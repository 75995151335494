/* eslint-disable no-useless-catch */
import client from './index';

const instance = client(true);

export default class UserCalls {
  static async getUsers() {
    const { data, status } = await instance.get(`/user/users`);
    return data.data;
  }

  static async getTopUsers() {
    const {
      data: { data },
      status,
    } = await instance.get('/user/topUsers');
    return data;
  }

  static async enableUser(userId) {
    const { data, status } = await instance.patch(`/user/${userId}/enable`);
    return status === 200;
  }

  static async disableUser(userId) {
    const { data, status } = await instance.patch(`/user/${userId}/disable`);
    return status === 200;
  }

  static async deleteUser(userId) {
    const { data, status } = await instance.delete(`/user/${userId}`);
    return status === 204;
  }

  static async elevateUser(userId) {
    const { data, status } = await instance.patch(`/user/${userId}/makeAgent`);
    return status === 200;
  }

  static async elevateToSubAgent(userId) {
    const { data, status } = await instance.patch(`/user/${userId}/makeSubAgent`);
  }

  static async demoteUser(userId) {
    const { data, status } = await instance.patch(`/user/${userId}/makeNonAgent`);
    return status === 200;
  }

  static async setClientToken(clientToken) {
    await instance.post(`/user/setClientToken`, { clientToken });
  }
}
