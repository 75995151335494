/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
import { actionTypes } from '../actions/giftcard';

const initialState = {
  giftcards: [],
  currentGiftcard: {},
  giftcardTypes: [],
  giftcardCountries: [],
  giftcardRanges: [],
  giftcardError: '',
  recentGiftcards: [],
};

export default function giftcardReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_GIFTCARDS:
      return {
        ...state,
        giftcards: action.payload,
      };

    case actionTypes.SET_GIFTCARD_TYPES:
      return {
        ...state,
        giftcardTypes: action.payload,
      };

    case actionTypes.SET_GIFTCARD_COUNTRIES:
      return {
        ...state,
        giftcardCountries: action.payload,
      };

    case actionTypes.SET_GIFTCARD_RANGES:
      return {
        ...state,
        giftcardRanges: action.payload,
      };

    case actionTypes.SET_GIFTCARD_ERROR:
      return {
        ...state,
        giftcardError: action.payload,
      };

    case actionTypes.SET_SELECTED_GIFTCARD:
      return {
        ...state,
        currentGiftcard: action.payload,
      };

    case actionTypes.SET_RECENT_GIFTCARDS:
      return {
        ...state,
        currentGiftcard: action.payload,
      };

    case actionTypes.DELETE_GIFTCARD:
      const newGiftcards = state.giftcards.filter((giftcard) => giftcard.id !== action.payload);
      return {
        ...state,
        giftcards: newGiftcards,
      };

    default:
      return state;
  }
}
