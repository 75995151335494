import React from 'react'
import { Box,Card, Typography, Stack, Checkbox, TextField, MenuItem, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function ChangePassword(){
    return (
        <Card sx={{ p: 5}}>
            <Typography sx={{fontSize: '14px'}}>Change Password</Typography>

            <Box as="form" sx={{mt: 5}}>
            <InputLabel shrink htmlFor="bootstrap-input">
          Current Password
        </InputLabel>
            <TextField fullWidth InputLabelProps={{ shrink: false }}
            sx={{mb: 3}}
            placeholder="Current Password"
                label=""
            />

<InputLabel shrink htmlFor="bootstrap-input">
          New Password
        </InputLabel>
            <TextField fullWidth InputLabelProps={{ shrink: false }}
            sx={{mb: 3}}
            placeholder="New Password"
                label=""
            />

<InputLabel shrink htmlFor="bootstrap-input">
          Confirm Password
        </InputLabel>
            <TextField fullWidth InputLabelProps={{ shrink: false }}
            sx={{mb: 3}}
            placeholder="Confirm Password"
                label=""
            />


            <LoadingButton size="large" type="submit" variant="contained" >
          Save
        </LoadingButton>
            </Box>


        </Card>

    )
}