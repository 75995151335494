/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Dialog,
  Button,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  DialogContentText,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';

import Iconify from '../../../components/Iconify';
import * as TradeActions from '../../../store/actions/trade';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
}));

const PendingOrder = ({ open, handlePendingClose, fullScreen, order }) => {
  const [reason, setReason] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { heldReasons } = useSelector((state) => state.trade);

  const handleChange = (event) => {
    setReason(event.target.value);
  };

  function handleSubmit() {
    dispatch(TradeActions.attendToGiftcardOrder('hold', order.orderId, reason));
    handlePendingClose();
    Swal.fire({
      title: 'Giftcard Placed On Hold',
      text: 'giftcard order has been placed on hold successfully',
      icon: 'success',
    });
    navigate('/dashboard/app');
  }

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handlePendingClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          display: 'block',
          margin: '0 auto',
        }}
      >
        <DialogContent>
          <IconWrapperStyle>
            <Iconify icon="ep:warning" width={'50%'} height={'50%'} color={'text.info'} />
          </IconWrapperStyle>
          <DialogContentText mt={2} textAlign="center">
            Order is on Hold
            <FormControl sx={{ mt: 1, minWidth: 200 }} fullWidth>
              <FormHelperText>Reason(s) for pending this order</FormHelperText>
              <Box sx={{ mb: 2 }} />
              <Select
                value={reason}
                onChange={handleChange}
                displayEmpty
                inputProps={{
                  'aria-label': 'Without label',
                }}
              >
                <MenuItem value="">
                  <em>Select Reason</em>
                </MenuItem>
                {heldReasons.map((reason) => (
                  <MenuItem key={reason.id} value={reason.id}>
                    {reason.reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <Box p={2}>
          <Button sx={{ width: '100%' }} variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default PendingOrder;
