import * as Redux from 'redux';

import giftcardReducer from './reducers/giftcard';
import userReducer from './reducers/user';
import statisticsReducer from './reducers/statistics';
import tradeReducer from './reducers/trade';
import ratesReducer from './reducers/rates';

export default Redux.combineReducers({
  giftcard: giftcardReducer,
  user: userReducer,
  statistics: statisticsReducer,
  trade: tradeReducer,
  rates: ratesReducer,
});
