/* eslint-disable react/prop-types */
import React from 'react';
import { Grid } from '@mui/material';
import GiftCardsCard from './GiftCardsCard';

export default function GiftCardsLists({ gifts }) {
  return (
    <Grid container spacing={3}>
      {gifts?.map((giftcard) => (
        <Grid key={giftcard.id} item xs={12} sm={6} md={3}>
          <GiftCardsCard gift={giftcard} />
        </Grid>
      ))}
    </Grid>
  );
}
