import StatisticsCalls from '../../api/statistics';

export const actionTypes = {
  SET_TOTAL_REVENUE: 'SET_TOTAL_REVENUE',
  SET_TOTAL_ORDERS: 'SET_TOTAL_ORDERS',
  SET_FULFILLED_ORDERS: 'SET_FULFILLED_ORDERS',
  SET_TOTAL_USERS: 'SET_TOTAL_USERS',
  SET_TOP_AGENTS: 'SET_TOP_AGENTS',
};

export function getTotalRevenue() {
  return async function (dispatch, getState) {
    const totalRevenue = await StatisticsCalls.getTotalRevenue();
    dispatch({
      type: actionTypes.SET_TOTAL_REVENUE,
      payload: totalRevenue,
    });
  };
}

export function getTotalOrders(status) {
  return async function (dispatch, getState) {
    const totalOrders = await StatisticsCalls.getTotalOrders(status);
    if (status || status === 'fulfilled') {
      dispatch({
        type: actionTypes.SET_FULFILLED_ORDERS,
        payload: totalOrders,
      });
    } else {
      dispatch({
        type: actionTypes.SET_TOTAL_ORDERS,
        payload: totalOrders,
      });
    }
  };
}

export function getTotalUsers() {
  return async function (dispatch, getState) {
    const totalUsers = await StatisticsCalls.getTotalUsers();
    dispatch({
      type: actionTypes.SET_TOTAL_USERS,
      payload: totalUsers,
    });
  };
}

export function getTopAgents() {
  return async function (dispatch, getState) {
    const topAgents = await StatisticsCalls.getTopAgents();
    dispatch({
      type: actionTypes.SET_TOP_AGENTS,
      payload: topAgents,
    });
  };
}
