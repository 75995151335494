/* eslint-disable default-param-last */
import { actionTypes } from '../actions/rates';

const initialState = {
  rates: [],
  currentRate: {},
  rateAdded: false,
};

export default function ratesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_RATES:
      return {
        ...state,
        rates: action.payload,
      };

    case actionTypes.SET_CURRENT_RATE:
      return {
        ...state,
        currentRate: action.payload,
      };

    case actionTypes.SET_RATE_ADDED:
      return {
        ...state,
        rateAdded: action.payload,
      };

    default:
      return state;
  }
}
