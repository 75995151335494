/* eslint-disable no-useless-catch */
import * as NotificationCalls from '../../api/notifications';

export const actionTypes = {
  SEND_NOTIFICATIONS: 'SEND_NOTIFICATIONS',
};

export function sendNotifications(rate) {
  return async function (dispatch, getState) {
    try {
      await NotificationCalls.sendNotifications(rate);
    } catch (error) {
      throw error;
    }
  };
}
