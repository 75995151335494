// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:home-outline'),
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: getIcon('eva:person-outline'),
  },
  {
    title: 'assets',
    path: '/dashboard/assets',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'orders',
    path: '/dashboard/orders',
    icon: getIcon('la:clipboard-list'),
  },
  {
    title: 'Rates',
    path: '/dashboard/rates',
    icon: getIcon('la:clipboard-list'),
  },
  {
    title: 'Notifications',
    path: '/dashboard/notifications',
    icon: getIcon('la:clipboard-list'),
  },
  {
    title: 'statistics',
    path: '/dashboard/statistics',
    icon: getIcon('icomoon-free:stats-bars'),
  },
  {
    title: 'settings',
    path: '/dashboard/settings',
    icon: getIcon('eva:settings-2-outline'),
  },
];

export default navConfig;
