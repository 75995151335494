import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, Button, AppBar, Toolbar, IconButton, Badge, Avatar, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import NotificationsPopover from './NotificationsPopover';
import { fDate } from '../../utils/formatTime';

// components
import Iconify from '../../components/Iconify';
//
import Searchbar from './Searchbar';
import * as AuthActions from '../../store/actions/user';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: '#fff',
  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.replace('/dashboard/', '');

  function handleLogout() {
    dispatch(AuthActions.logout());
    Swal.fire({
      icon: 'info',
      title: 'Logout Successfully',
      text: 'You have successfully been logged out of your account',
      buttonsStyling: true,
    });
    navigate('/', { replace: true });
  }

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {(path === 'orders' || path === 'users') && <Searchbar />}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <Typography as="h3" sx={{ fontWeight: '700', fontSize: '16px', color: 'text.primary' }}>
            Today:
          </Typography>
          <Typography as="h3" sx={{ fontWeight: '700', fontSize: '16px', color: 'text.primary', mr: 10 }}>
            {new Date(Date.now()).toLocaleString()}
          </Typography>
          {/* <NotificationsPopover /> */}
          <IconButton sx={{ width: 40, height: 40 }} onClick={() => handleLogout()}>
            <Badge>
              <Iconify icon="ep:switch-button" width={20} height={20} />
            </Badge>
          </IconButton>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
