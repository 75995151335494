import React, { useState } from 'react';
import { Box, Typography, Card, Tab, Stack } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import LineChart from '../../../components/chart/LineChart';

export default function TradeHistory() {
  const [value, setValue] = useState('1');
  const [openFulfill, setOpenFulfill] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const lineOptions = {
    plugins: {
      datalabels: {
        render: 'label',
      },
      legend: {
        display: false,
      },
    },
  };

  const lineuserData = {
    labels: ['01', '02', '03', '04', '05', '06', '07'],
    datasets: [
      {
        data: ['20', '40', '60', '80', '100', '120'],

        fill: {
          target: 'origin',
          above: 'rgba(0, 124, 246)', // Area will be red above the origin
          below: 'rgb(0, 0, 255)', // And blue below the origin
        },
      },
    ],
  };

  return (
    <Box>
      <Card>
        <Typography sx={{ fontSize: 18, p: 2 }} color="text.secondary" gutterBottom>
          Trade History
        </Typography>

        <TabContext value={value}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <TabList
              onChange={handleChange}
              aria-label="tabs"
              sx={{
                px: 2,
              }}
            >
              <Tab label="Daily" value="1" />
              <Tab label="Weekly" value="2" />
              <Tab label="Monthly" value="3" />
            </TabList>
            {/* <Button variant="contained" onClick={handleFullfillOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
              Add New Asset
            </Button> */}
          </Stack>
          <TabPanel
            flex-wrap
            value="1"
            sx={{
              px: 20,
            }}
          >
            <LineChart chartData={lineuserData} options={lineOptions} />
          </TabPanel>
        </TabContext>
      </Card>
    </Box>
  );
}
