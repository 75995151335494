/* eslint-disable no-useless-catch */
import client from './index';

const instance = client(true);

export default class GiftcardCalls {
  static async getGiftcards() {
    try {
      const { data, status } = await instance.get(`/giftcard/giftcards`);
      if (status === 200) {
        return data.data;
      }
    } catch (error) {
      throw error;
    }
  }

  static async getGiftcardTypes(giftcardId) {
    try {
      const { data, status } = await instance.get(`/giftcard/types?giftcard=${giftcardId}`);
      if (status === 200) {
        return data.data;
      }
    } catch (error) {
      throw error;
    }
  }

  static async getGiftcardRanges() {
    try {
      const { data, status } = await instance.get(`/giftcard/ranges`);
      if (status === 200) {
        return data.data;
      }
    } catch (error) {
      throw error;
    }
  }

  static async getGiftcardCountries() {
    try {
      const { data, status } = await instance.get(`/giftcard/countries`);
      if (status === 200) {
        return data.data;
      }
    } catch (error) {
      throw error;
    }
  }

  static async deleteGiftcard(giftId) {
    try {
      await instance.delete(`/giftcard/${giftId}`);
    } catch (error) {
      throw error;
    }
  }
}
