/* eslint-disable default-param-last */
import { actionTypes } from '../actions/user';

const initialState = {
  authToken: '',
  user: {},
  userError: '',
  loginSuccess: false,
  users: [],
  topUsers: [],
  agents: [],
  selectedUser: null,
  hasTriedLogin: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_AUTH_TOKEN:
      localStorage.setItem('coastauth', JSON.stringify(action.payload));
      return {
        ...state,
        authToken: action.payload,
      };

    case actionTypes.SET_USER:
      localStorage.setItem('coastuser', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      };

    case actionTypes.SET_USER_ERROR:
      return {
        ...state,
        userError: action.payload,
      };

    case actionTypes.SET_LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccess: action.payload,
      };

    case actionTypes.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case actionTypes.SET_TOP_USERS:
      return {
        ...state,
        topUsers: action.payload,
      };

    case actionTypes.SET_AGENTS:
      return {
        ...state,
        agents: action.payload,
      };

    case actionTypes.SET_SUBAGENTS:
      return {
        ...state,
        subagents: action.payload,
      };

    case actionTypes.SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };

    case actionTypes.SET_TRIED_LOGIN:
      return {
        ...state,
        selectedUser: action.payload,
      };

    case actionTypes.LOG_OUT:
      localStorage.setItem('coastauth', null);
      localStorage.setItem('coastuser', null);
      return state;

    default:
      return state;
  }
}
