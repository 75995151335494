/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Box, Dialog, Button, Stack, DialogContent, DialogContentText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from '../../../components/Iconify';

// import { useGlobalContext } from '../../../context';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
}));

const DeleteDialog = ({ deleteCard, open, deleteClose, fullScreen }) => (
  <Box>
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={deleteClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        display: 'block',
        margin: '0 auto',
        width: '400px',
      }}
    >
      <DialogContent sx={{ textAlign: 'center' }}>
        <DialogContentText mt={2} sx={{ fontWeight: '700', fontSize: '20px' }}>
          Delete iTunes Giftcard?
        </DialogContentText>
        <Typography>
          Deleting this giftcard would remove the giftcard and rates from your list of assets. Are you sure you want to
          delete iTunes from your giftcard lists?
        </Typography>
      </DialogContent>
      <Stack p={2} flexDirection="row" justifyContent="flex-end">
        <Button autoFocus onClick={deleteClose}>
          CANCEL
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            deleteCard();
            deleteClose();
          }}
        >
          YES DELETE
        </Button>
      </Stack>
    </Dialog>
  </Box>
);

export default DeleteDialog;
