import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// material
import { styled, alpha } from '@mui/material/styles';
import { Input, Slide, Button, TextField, IconButton, InputAdornment, ClickAwayListener } from '@mui/material';
// component

import Iconify from '../../components/Iconify';
import { actionTypes, setUsers } from '../../store/actions/user';
import { actionTypes as TradeActionTypes } from '../../store/actions/trade';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: 'none',
}));

const Search = styled(TextField)(({ theme }) => ({
  color: 'inherit',
  position: 'relative',
  left: '20%',
  '& .MuiOutlinedInput-input ': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    // transition: theme.transitions.create('width'),
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
  '.Mui-focused': {
    border: '1px solid #eee',
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [value, setValue] = useState('');
  const location = useLocation();
  const path = location.pathname.replace('/dashboard/', '');

  const dispatch = useDispatch();
  const { users, agents } = useSelector((state) => state.user);
  const [initialUsers, setInitialUsers] = useState([]);
  const [initialAgents, setInitialAgents] = useState([]);

  const { giftcardOrders: orders } = useSelector((state) => state.trade);
  const [initialOrders, setInitialOrders] = useState([]);

  useEffect(() => {
    setInitialUsers(users);
    setInitialAgents(agents);
    setInitialOrders(orders);
  }, []);

  function handleTextChange(event) {
    let usersToFilterFrom = users;
    let agentsToFilterFrom = agents;
    let ordersToFilterFrom = orders;

    if (event.which === 8 || event.code === 'Backspace') {
      usersToFilterFrom = initialUsers;
      agentsToFilterFrom = initialAgents;
      ordersToFilterFrom = initialOrders;
    }

    const value = event.target.value;
    setValue(value);

    if (path === 'users') {
      if (!value.trim()) {
        dispatch(setUsers());
      }
      const filteredUsers = usersToFilterFrom.filter(
        (user) =>
          user.user_tag.toLowerCase().includes(value.toLowerCase()) ||
          user.first_name.toLowerCase().includes(value.toLowerCase()) ||
          user.last_name.toLowerCase().includes(value.toLowerCase()) ||
          user.email.toLowerCase().includes(value.toLowerCase())
      );
      const filteredAgents = agentsToFilterFrom.filter(
        (agent) =>
          agent.user_tag.toLowerCase().includes(value.toLowerCase()) ||
          agent.first_name.toLowerCase().includes(value.toLowerCase()) ||
          agent.last_name.toLowerCase().includes(value.toLowerCase()) ||
          agent.email.toLowerCase().includes(value.toLowerCase())
      );

      if (!filteredUsers.length && !filteredAgents.length) {
        dispatch(setUsers());
      }

      dispatch({
        type: actionTypes.SET_USERS,
        payload: filteredUsers,
      });
      dispatch({
        type: actionTypes.SET_AGENTS,
        payload: filteredAgents,
      });
    } else if (path === 'orders') {
      if (!value.trim()) {
        dispatch({
          type: TradeActionTypes.SET_GIFTCARD_ORDERS,
          payload: ordersToFilterFrom,
        });
      }
      const filteredOrders = ordersToFilterFrom.filter(
        (order) =>
          order.user_tag.toLowerCase().includes(value.toLowerCase()) ||
          order.name.toLowerCase().includes(value.toLowerCase()) ||
          order.value.toLowerCase().includes(value.toLowerCase()) ||
          order.rate.toLowerCase().includes(value.toLowerCase()) ||
          order.attended_by.toLowerCase().includes(value.toLowerCase()) ||
          order.amount_in_naira.toLowerCase().includes(value.toLowerCase())
      );

      if (!filteredOrders.length) {
        dispatch({
          type: TradeActionTypes.SET_GIFTCARD_ORDERS,
          payload: initialOrders,
        });
      }

      dispatch({
        type: TradeActionTypes.SET_GIFTCARD_ORDERS,
        payload: filteredOrders,
      });
    }
  }

  function handleSearch() {
    console.log(value);
  }

  return (
    <Search
      id="standard-bare"
      variant="outlined"
      size="small"
      placeholder="Search…"
      onKeyUp={(event) => handleTextChange(event)}
      InputProps={{
        endAdornment: (
          <IconButton onClick={(event) => handleSearch(event)}>
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </IconButton>
        ),
      }}
    />
  );
}
