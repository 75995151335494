import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as UserActions from '../../../store/actions/user';

function Agents({ name, amount, image, userId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleUserSelect(userId) {
    dispatch(UserActions.setSelectedUser(userId));
    navigate(`/dashboard/users/${userId}`);
  }

  return (
    <Stack spacing={3} sx={{ py: 1 }}>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ width: 1 }}>
        <Box sx={{ width: 38, height: 38 }}>
          <Box
            component="img"
            src={image}
            alt={`${name}`}
            sx={{ width: '100%', height: '100%', borderRadius: '50%' }}
          />
        </Box>
        <Box sx={{ width: 150 }}>
          <Typography
            color="inherit"
            variant="subtitle2"
            onClick={userId && handleUserSelect.bind(null, userId)}
            sx={{ cursor: 'pointer' }}
          >
            {name}
          </Typography>
        </Box>

        <Box sx={{ width: 100 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {amount}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}

export default Agents;
