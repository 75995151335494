/* eslint-disable no-nested-ternary */

import React from 'react';
import { Box, Stack, Typography, CardHeader } from '@mui/material';
import { Circle } from '@mui/icons-material';

export default function RecentTransaction({ title, name, amount, attendedBy, status }) {
  return (
    <Stack spacing={3} sx={{ py: 1 }}>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ width: 1 }}>
        <Box sx={{ minWidth: 100 }}>
          <Typography color="inherit" variant="subtitle2">
            {name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {`By ${attendedBy}`}
          </Typography>
        </Box>

        <Box sx={{ minWidth: 100 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {amount}
          </Typography>
        </Box>

        <Box sx={{ minWidth: 100 }}>
          <Typography
            variant="body2"
            sx={{
              color: status === 'fulfilled' ? 'text.success' : status === 'pending' ? 'text.warning' : 'text.danger',
              fontStyle: 'italic',
            }}
          >
            <Stack direction="row" spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center">
              <Circle sx={{ fontSize: '6px' }} /> {status}{' '}
            </Stack>
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}
