/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, TextField, Stack, Container, Dialog, DialogContent } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Page from '../components/Page';
import * as NotificationActions from '../store/actions/notifications';

const Notifications = () => {
  const [newNotificationOpen, setNewNotificationOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();

  const clear = () => {
    setSubject('');
    setMessage('');
  };

  const sendNotifications = async () => {
    try {
      setNewNotificationOpen(false);
      if (subject.trim() === '' || message.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'Invalid input',
          text: 'Please enter values for both subject and message',
          buttonsStyling: true,
        });
      } else {
        const data = {
          subject,
          message,
        };
        dispatch(NotificationActions.sendNotifications(data));
        Swal.fire({
          icon: 'success',
          title: 'Notifications sent',
          text: 'Notifications have been sent successfully',
          buttonsStyling: true,
          didClose: () => {
            clear();
          },
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Notifications not sent',
        text: 'Server or network error',
        buttonsStyling: true,
      });
    }
  };

  return (
    <Page title="Notifications">
      <Container sx={{ height: '100%' }}>
        <Stack direction="row" justifyContent="space-between">
          <h2>Notification</h2>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              setNewNotificationOpen(true);
            }}
          >
            New Notification
          </Button>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center" />
      </Container>
      <Dialog
        onClose={() => {
          setNewNotificationOpen(false);
        }}
        open={newNotificationOpen}
      >
        <DialogContent>
          <Stack direction="column" sx={{ width: '508px' }}>
            <Stack direction="row" justifyContent="space-between">
              <h3>Create User Notification</h3>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => setNewNotificationOpen(false)}
                role="button"
                tabIndex={0}
              >
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.88428 20.1165L14.0011 13.9996M20.1179 7.88281L13.9999 13.9996M13.9999 13.9996L7.88428 7.88281M14.0011 13.9996L20.1179 20.1165"
                    stroke="black"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </Stack>

            <div style={{ marginTop: '24px' }} />
            <div style={{ color: '#B2B2B2', fontSize: '16px', fontWeight: 400 }}>Subject</div>
            <TextField
              sx={{ marginBottom: '44px' }}
              id="outlined-basic"
              placeholder="Enter Subject Here"
              variant="outlined"
              onChange={(e) => {
                setSubject(e.target.value);
              }}
            />

            <div style={{ color: '#B2B2B2', fontSize: '16px', fontWeight: 400 }}>Body</div>
            <TextField
              id="outlined-basic"
              placeholder="The message for the notification"
              variant="outlined"
              multiline
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              rows={5}
              sx={{ marginBottom: '48px' }}
            />

            <Button
              variant="contained"
              sx={{
                marginBottom: '16px',
              }}
              onClick={sendNotifications}
            >
              Continue
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Page>
  );
};

export default Notifications;
