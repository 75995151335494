import TradeCalls from '../../api/trade';

export const actionTypes = {
  SET_GIFTCARD_ORDERS: 'SET_GIFTCARD_ORDERS',
  SET_CURRENT_ORDER: 'SET_CURRENT_ORDER',
  SET_REJECTED_REASONS: 'SET_REJECTED_REASONS',
  SET_HELD_REASONS: 'SET_HELD_REASONS',
  SET_ATTENDED_SUCCEED: 'SET_ATTENDED_SUCCEED',
};

export function getGiftcardOrders(status) {
  return async function (dispatch, getState) {
    let giftcardOrders;
    if (status && status !== 'all') {
      giftcardOrders = getState().trade.giftcardOrders.filter((order) => order.status === status);
    } else {
      giftcardOrders = await TradeCalls.getGiftcardOrders();
    }
    dispatch({
      type: actionTypes.SET_GIFTCARD_ORDERS,
      payload: giftcardOrders,
    });
  };
}

export function setCurrentOrder(orderId) {
  return async function (dispatch, getState) {
    const orders = getState().trade.giftcardOrders;
    const currentOrder = orders.find((order) => order.order_id === orderId);
    dispatch({
      type: actionTypes.SET_CURRENT_ORDER,
      payload: currentOrder,
    });
  };
}

export function setGiftcardReasons(type) {
  return async function (dispatch, getState) {
    const reasons = await TradeCalls.getGiftcardReasons(type);
    dispatch({
      type: type === 'rejected' ? actionTypes.SET_REJECTED_REASONS : actionTypes.SET_HELD_REASONS,
      payload: reasons,
    });
  };
}

export function attendToGiftcardOrder(action, orderId, reason) {
  return async function (dispatch, getState) {
    const result = await TradeCalls.attendToGiftcardOrder(action, orderId, reason);
    dispatch({
      type: actionTypes.SET_ATTENDED_SUCCEED,
      payload: result,
    });
  };
}
