/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

// material
import {
  Stack,
  Tab,
  Button,
  useMediaQuery,
  Container,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { GiftCards, BitCoin, NewAsset } from '../sections/@dashboard/asset';
import AddGiftCard from '../sections/@dashboard/asset/AddGiftCard';

export default function User() {
  const [value, setValue] = useState('1');
  const [openAsset, setOpenAsset] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { giftcards, recentGiftcards } = useSelector((state) => state.giftcard);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleAddAssetOpen = () => {
    setOpenAsset(true);
  };
  const handleAddAssetClose = () => {
    setOpenAsset(false);
  };

  return (
    <Page title="Asset">
      <Container>
        <TabContext value={value}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <TabList
              onChange={handleChange}
              aria-label="tabs"
              sx={{
                px: 2,
              }}
            >
              <Tab label="Giftcards" value="1" />
              <Tab label="Crypto" value="2" />
            </TabList>
            <Button variant="contained" onClick={handleAddAssetOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
              Add New Asset
            </Button>
          </Stack>
          <TabPanel
            flex-wrap
            value="1"
            sx={{
              px: 0,
            }}
          >
            <GiftCards gifts={giftcards} />
          </TabPanel>
          <TabPanel
            flex-wrap
            value="2"
            sx={{
              px: 0,
            }}
          >
            {''}
          </TabPanel>
        </TabContext>
        <Box>
          <Typography color="text.secondary">Recently Added</Typography>
          <NewAsset gifts={recentGiftcards} />
        </Box>
        <AddGiftCard open={openAsset} handleAddAssetClose={handleAddAssetClose} fullScreen={fullScreen} />
      </Container>
    </Page>
  );
}
