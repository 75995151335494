/* eslint-disable no-useless-catch */
import client from './index';
import { fCurrency } from '../utils/formatNumber';

const instance = client(true);

export default class StatisticsCalls {
  static async getTotalRevenue() {
    const { data, status } = await instance.get(`/statistics/totalGiftcardOrdersPrice`);
    return fCurrency(data.data.totalamountoforders);
  }

  static async getTotalOrders(type) {
    const url =
      type === 'fulfilled'
        ? `/statistics/totalGiftcardOrdersMade?status=${type}`
        : `/statistics/totalGiftcardOrdersMade`;
    const { data, status } = await instance.get(url);
    return data.data.totalgiftcardorders;
  }

  static async getTotalUsers() {
    const { data, status } = await instance.get(`/statistics/totalUsers`);
    return data.data[0].totalusers;
  }

  static async getTopAgents() {
    const { data, status } = await instance.get('/statistics/topAgents');
    return data.data;
  }
}
