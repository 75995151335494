import React from 'react';
import {
  Box,
  Card,
  Typography,
  Stack,
  Checkbox,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function AccountSetting() {
  const country = ['Nigeria', 'USA'];

  return (
    <Card sx={{ p: 5 }}>
      <Typography sx={{ fontSize: '14px' }}>Account Setting</Typography>

      <Stack justifyContent={'center'} alignItems={'center'} sx={{ mt: 3 }}>
        <Box component="img" alt="profile" src="/static/mock-images/avatars/avatar_27.png" />
      </Stack>
      <Box as="form" sx={{ mt: 5 }}>
        <InputLabel shrink htmlFor="bootstrap-input">
          FullName
        </InputLabel>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: false }}
          sx={{ mb: 3 }}
          placeholder="Please enter text"
          label=""
        />

        <InputLabel shrink htmlFor="bootstrap-input">
          Email Address
        </InputLabel>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: false }}
          sx={{ mb: 3 }}
          placeholder="Please enter text"
          label=""
        />

        <InputLabel shrink htmlFor="bootstrap-input">
          Role
        </InputLabel>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: false }}
          sx={{ mb: 3 }}
          placeholder="Please enter text"
          label=""
        />

        <InputLabel shrink htmlFor="bootstrap-input">
          Phone Number
        </InputLabel>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: false }}
          sx={{ mb: 3 }}
          placeholder="Please enter text"
          label=""
        />

        <InputLabel shrink htmlFor="bootstrap-input">
          Country
        </InputLabel>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: false }}
          sx={{ mb: 3 }}
          select
          placeholder="Please enter text"
          value="Nigeria"
          defaultValue="Nigeria"
        >
          {country.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <LoadingButton size="large" type="submit" variant="contained">
          Update Profile
        </LoadingButton>
      </Box>
    </Card>
  );
}
