/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import {
  Grid,
  Button,
  Typography,
  InputLabel,
  Tab,
  Box,
  Paper,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  TextField,
  Stack,
} from '@mui/material';
import Iconify from '../../../components/Iconify';
import * as RateActions from '../../../store/actions/rates';

export default function Physical({ rate }) {
  const [edit, setEdit] = React.useState(false);
  const [newRate, setNewRate] = React.useState(+rate.rate)

  const dispatch = useDispatch();

  function handleDeleteRate(rateId) {
    dispatch(RateActions.deleteRate(rateId));
    Swal.fire({
      icon: 'success',
      title: 'Rate Deleted',
      text: 'Rate Deleted Successfully',
      didClose: () => dispatch(RateActions.getAllRates()),
      buttonsStyling: true,
    });
    dispatch(RateActions.getAllRates());
  }

  function handleChange(event) {
    setNewRate(event.target.value);
  }
  
  function handleRateChange(event) {
    if (event.charCode === 13 || event.code === 'Enter') {
      try {
        dispatch(RateActions.updateRate(newRate, rate.id));
        Swal.fire({
        icon: 'success',
        title: 'Rate Updated',
        text: 'Rate has been updated successfully',
        didClose: () => dispatch(RateActions.getAllRates()),
        buttonsStyling: true,
      });
      } catch (error) {
        Swal.fire({
        icon: 'error',
        title: 'Error Updating Rate',
        text: 'Unable to update rate',
        buttonsStyling: true,
      });
      }
      dispatch(RateActions.getAllRates())
      setEdit(false);
    }
    setNewRate(event.target.value);
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography color="inherit" variant="subtitle2" wrap>
          {rate.giftcard}
        </Typography>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Typography color="inherit" variant="subtitle2">
          {rate.country}
        </Typography>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Typography color="inherit" variant="subtitle2" wrap>
          {rate.range}
        </Typography>
      </Box>

      <Box sx={{ width: '100%', cursor: 'pointer' }}>
        {
          edit ? <TextField
                      id="rate"
                      name="rate"
            type="number"
            onChange={handleChange}
            onKeyUp={handleRateChange}
                      value={newRate}
                      fullWidth={false}
                      onBlur={() => setEdit(false)}
                      autoFocus
                    /> : <Typography variant="body2" sx={{ color: 'text.secondary' }} onClick={() => setEdit(edit => !edit)}>
            {`$${rate.rate}`}
          </Typography>
        }
      </Box>

      <Box sx={{ width: '100%' }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${rate.type}`}
        </Typography>
      </Box>

      <Box sx={{ width: '80%' }}>
        <Iconify
          icon="fluent:delete-20-filled"
          color={'red'}
          style={{ cursor: 'pointer' }}
          onClick={handleDeleteRate.bind(null, rate.id)}
        />
      </Box>
    </>
  );
}
