/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Box, Dialog, Button, Stack, DialogContent, DialogContentText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from '../../../components/Iconify';

// import { useGlobalContext } from '../../../context';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
}));

const ConfirmationDialog = ({ action, open, close, fullScreen, title, content }) => (
  <Box>
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
      sx={{
        display: 'block',
        margin: '0 auto',
        width: '400px',
      }}
    >
      <DialogContent sx={{ textAlign: 'center' }}>
        <DialogContentText mt={2} sx={{ fontWeight: '700', fontSize: '20px' }}>
          {title}
        </DialogContentText>
        <Typography>{content}</Typography>
      </DialogContent>
      <Stack p={2} flexDirection="row" justifyContent="flex-end">
        <Button autoFocus onClick={close}>
          CANCEL
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            action();
            close();
          }}
        >
          YES DELETE
        </Button>
      </Stack>
    </Dialog>
  </Box>
);

export default ConfirmationDialog;
