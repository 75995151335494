/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
// import { DropzoneArea } from 'material-ui-dropzone';
import {
  Box,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Dialog,
  FormControl,
  MenuItem,
  OutlinedInput,
  FormLabel,
  Button,
  DialogContent,
  DialogContentText,
  Select,
  InputLabel,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from '../../../components/Iconify';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
}));

const AddGiftCard = ({ open, handleAddAssetClose, fullScreen }) => {
  const [country, setCountry] = useState('');
  const [trade, setTrade] = useState('');
  const [unit, setUnit] = useState('');

  const handleChange = (event) => {
    setCountry(event.target.value);
  };

  const handleTradeChange = (event) => {
    setTrade(event.target.value);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleAddAssetClose}
        sx={{
          display: 'block',
          margin: '0 auto',
          width: '500px',
        }}
      >
        <DialogContent>
          <IconWrapperStyle>
            <FormLabel>
              <TextField name="upload-photo" type="file" sx={{ display: 'none' }} />
              <Iconify width={'30%'} height={'30%'} style={{ cursor: 'pointer' }} icon="akar-icons:cloud-upload" /> {''}
            </FormLabel>
          </IconWrapperStyle>

          <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Browse and Upload file</Typography>
          <DialogContentText mt={5}>
            <Box as="form" width="100%">
              <TextField placeholder="Name" name="name" label="Name" required sx={{ width: '100%', mb: '15px' }} />

              <FormControl sx={{ width: '100%', mb: '15px' }}>
                <FormLabel >Receipt Needed</FormLabel>
                <RadioGroup
                  name="receipt-needed"
                >
                  <FormControlLabel value="true" control={<Radio />} label="true" />
                  <FormControlLabel value="false" control={<Radio />} label="False" />
                </RadioGroup>
              </FormControl>


              <FormControl sx={{ width: '100%', mb: '15px' }}>
                <InputLabel id="demo-select-small">Country</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={country}
                  label="Country"
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ width: '100%', mb: '15px' }}>
                <InputLabel id="demo-select-small">Estimated trade time</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={trade}
                  label="Estimated trade time"
                  onChange={handleTradeChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ width: '100%', mb: '15px' }}>
                <InputLabel id="demo-select-small">Estimated trade time unit</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={trade}
                  label="Estimated trade time unit"
                  onChange={handleUnitChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>minutes</MenuItem>
                  <MenuItem value={20}>hours</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Box p={2}>
          <Button sx={{ width: '100%' }} variant="contained" onClick={handleAddAssetClose}>
            Create New Giftcard
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AddGiftCard;
