/* eslint-disable no-useless-return */
import React from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Swal from 'sweetalert2';

// component
import Iconify from '../../../components/Iconify';
import * as AuthActions from '../../../store/actions/user';

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const [usernameLogin, setUsernameLogin] = React.useState(false);
  const [emailLogin, setEmailLogin] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  async function login(values) {
    const regx = /[a-z0-9]+@[a-z]+.[a-z]{2,3}/;
    if (values.username.match(regx)) {
      setEmailLogin(true);
      setUsernameLogin(false);
    } else {
      setUsernameLogin(true);
      setEmailLogin(false);
    }

    const data = {
      username: values.username,
      password: values.password,
    };
    if (data.username.includes('@')) {
      data.email = data.username;
      data.username = undefined;
    }
    try {
      const { data: result, status } = await axios.post('https://api.coast.ng/api/v1/auth/login', data);
      if (status !== 200) {
        throw new Error();
      }
      navigate('/get-token', { replace: false });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication failed',
        text: 'Unable to login, please check your credentials.',
      });
    }
  }

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      username: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(AuthActions.resetLogin());

      if (values.email || values.username) {
        if (values.password) {
          login(values);
        }
      }
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            shrink
            autoComplete="username"
            type="username"
            label="Username"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            shrink
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            sx={{ color: 'text.secondary' }}
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link
            sx={{ color: 'text.secondary' }}
            variant="subtitle2"
            to="/forgot-password"
            underline="hover"
            onClick={() => navigate('forgot-password')}
          >
            Forgot password?
          </Link>
        </Stack>

        <Button fullWidth size="large" type="submit" variant="contained">
          Login
        </Button>

        {errorMsg && <Typography sx={{ color: 'text.danger' }}>{errorMsg}</Typography>}
      </Form>
    </FormikProvider>
  );
}
