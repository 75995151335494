import React, {useState} from 'react'
import { Box, Stack, Card, Typography, Grid } from '@mui/material';
import LineChart from '../../../components/chart/LineChart';



export default function PlatformType() {

    const areaOptions = {
        plugins: {
            datalabels: {
              render: 'label',
            },
            legend: {
              display: false,
            },
            maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }
      };  

    const [areauserData, setAreaUserData] = useState({
        labels: ['May', 'June', 'July', 'August', 'September'],
      datasets: [
        {
            data: ['20',
            '40',
            '60',
            '80',
            '100',
            '120'],
            borderColor:'#007CF6',

        },
        {
              label: 'Quantity',
              data: [47, 52, 67, 58, 9, 50],
              backgroundColor: '#007CF6',
              borderColor: '#6AD2FF',
            },
      ],
    });
    return (
        <Box>
      <Card>
        <Typography sx={{ fontSize: 18, p: 2 }} color="text.secondary" gutterBottom>
        Platform Type
        </Typography>

            <LineChart chartData={areauserData}
                                options={areaOptions} />


                                <Typography sx={{ fontSize: 13, py: 2, textAlign: 'center' }} color="text.main">Number of Users VS Month</Typography>




        </Card>
        </Box>
    )
} 