import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Box, Container, Typography } from '@mui/material';
// hooks
import Grid from '@material-ui/core/Grid';
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import logo from '../images/logo.png';
// sections
import { TokenForm } from '../sections/auth/token';
import Logo from '../images/coast.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  backgroundColor: '#FFFFFF',
  height: '100vh',
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const BoxStyle = styled(Card)(({ theme }) => ({
  overflow: 'hidden',
  backgroundColor: '#0655FF',
  borderRadius: '0px',
  height: '100vh',
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 552,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '0px',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '90px 12px',
  backgroundColor: '#FFFFFF',
}));

const ImgStyle = styled('div')(({ theme }) => ({
  maxWidth: 185,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------

export default function GetToken() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="GetToken">
      <RootStyle>
        {
          <Grid container sx={{ zIndex: '1000' }} columnSpacing={{ xs: 1, sm: 2, md: 3 }} item sm={6} md={6} xs={12}>
            <Box
              component="img"
              src={Logo}
              alt={`logo`}
              sx={{
                minHeight: 'auto',
                objectFit: mdUp ? 'cover' : 'contain',
                maxWidth: '100%',
              }}
            />
            {''}
            {''}
          </Grid>
        }

        <Grid container sx={{ zIndex: '500' }} item xs={12} sm={6} md={6}>
          <ContentStyle>
            <ImgStyle>
              <Box component="img" alt="token" src="/static/mock-images/covers/cover_32.png" />
            </ImgStyle>
            <Typography variant="h4" sx={{ color: 'text.main', mt: -5 }} gutterBottom>
              Check your mail
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>A token has been sent to your email.</Typography>

            <TokenForm />
          </ContentStyle>
        </Grid>
      </RootStyle>
    </Page>
  );
}
