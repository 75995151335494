/* eslint-disable no-useless-catch */
import * as RateCalls from '../../api/rates';

export const actionTypes = {
  SET_RATES: 'SET_RATES',
  SET_CURRENT_RATE: 'SET_CURRENT_RATE',
  SET_RATE_ADDED: 'SET_RATE_ADDED',
  UPDATE_RATE: 'UPDATE_RATE',
};

export function getAllRates() {
  return async function (dispatch, getState) {
    const rates = await RateCalls.getAllRates();
    dispatch({
      type: actionTypes.SET_RATES,
      payload: rates,
    });
  };
}

export function deleteRate(rateId) {
  return async function (dispatch, getState) {
    await RateCalls.deleteRate(rateId);
  };
}

export function createRate(rate) {
  return async function (dispatch, getState) {
    try {
      await RateCalls.createRate(rate);
    } catch (error) {
      throw error;
    }
  };
}

export function updateRate(newRate, rateId) {
  return async function (dispatch, getState) {
    try {
      await RateCalls.updateRate(newRate, rateId);
    } catch (error) {
      throw error;
    }
  }
}
