/* eslint-disable default-param-last */
import { actionTypes } from '../actions/trade';

const initialState = {
  giftcardOrders: [],
  currentOrder: {},
  rejectedReasons: [],
  heldReasons: [],
  attendedSucceed: false,
};

export default function tradeReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_GIFTCARD_ORDERS:
      return {
        ...state,
        giftcardOrders: action.payload,
      };

    case actionTypes.SET_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: action.payload,
      };

    case actionTypes.SET_REJECTED_REASONS:
      return {
        ...state,
        rejectedReasons: action.payload,
      };

    case actionTypes.SET_HELD_REASONS:
      return {
        ...state,
        heldReasons: action.payload,
      };

    case actionTypes.SET_ATTENDED_SUCCEED:
      return {
        ...state,
        attendedSucceed: action.payload,
      };

    default:
      return state;
  }
}
