import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

// material
import { Stack, Button, Container, FormControl, MenuItem, Select } from '@mui/material';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { OrderByDate } from '../sections/@dashboard/order';
import USERLIST from '../_mock/user';
import * as TradeActions from '../store/actions/trade';

const columns = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'value', label: 'Value', alignRight: false },
  { id: 'rate', label: 'Rate', alignRight: false },
  { id: 'amount', label: 'Amount(#)', alignRight: false },
  { id: 'agent', label: 'Agent', alignRight: false },
  { id: 'time', label: 'time', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

function createData(name, value, rate, amount, agent, time, status) {
  return { name, value, rate, amount, agent, time, status };
}

const rows = [
  // createData('Amazon Giftcard', '$500', '#700/$', '#350,000', 'Adedayo Pelumi', '08:52am', 'Completed'),
  // createData('Amazon Giftcard', '$500', '#700/$', '#350,000', 'Adedayo Pelumi', '08:52am', 'Completed'),
  // createData('Amazon Giftcard', '$500', '#700/$', '#350,000', 'Adedayo Pelumi', '08:52am', 'Pending'),
  // createData('Amazon Giftcard', '$500', '#700/$', '#350,000', 'Adedayo Pelumi', '08:52am', 'Completed'),
  // createData('Amazon Giftcard', '$500', '#700/$', '#350,000', 'Adedayo Pelumi', '08:52am', 'Completed'),
  // createData('Amazon Giftcard', '$500', '#700/$', '#350,000', 'Adedayo Pelumi', '08:52am', 'Completed'),
  // createData('Amazon Giftcard', '$500', '#700/$', '#350,000', 'Adedayo Pelumi', '08:52am', 'Completed'),
  // createData('Amazon Giftcard', '$500', '#700/$', '#350,000', 'Adedayo Pelumi', '08:52am', 'Rejected'),
  // createData('Amazon Giftcard', '$500', '#700/$', '#350,000', 'Adedayo Pelumi', '08:52am', 'Completed'),
  // createData('Amazon Giftcard', '$500', '#700/$', '#350,000', 'Adedayo Pelumi', '08:52am', 'Completed'),
];

export default function Orders() {
  const useStyles = makeStyles({
    btn: {
      backgroundColor: '#007CF6',
      border: 'none',
      fontSize: '12px',
      color: '#fff',

      '&:hover': {
        backgroundColor: '#007CF6',
      },
    },
    selectForm: {
      width: '100px',
    },
    selectInput: {
      borderColor: 'gray',
      fontSize: '11px',
    },
    faintButton: {
      backgroundColor: 'transparent',
      fontStyle: 'italic',
    },
  });

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [status, setStatus] = useState('');
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const status = event.target.value;

    setStatus(status);
    Promise.resolve(dispatch(TradeActions.getGiftcardOrders())).then(() =>
      dispatch(TradeActions.getGiftcardOrders(status))
    );
  };

  React.useEffect(() => {
    dispatch(TradeActions.getGiftcardOrders(status));
  }, [status]);

  return (
    <Page title="Orders">
      <Container>
        <Stack
          direction="row"
          bgcolor={'background.paper'}
          sx={{ borderRadius: 1, px: 4, py: 1 }}
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-around">
            <FormControl sx={{ mt: 1, minWidth: 100 }}>
              <Select
                value={status}
                onChange={handleChange}
                displayEmpty
                inputProps={{
                  'aria-label': 'Without label',
                }}
              >
                <MenuItem value="">
                  <em>Status</em>
                </MenuItem>
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'fulfilled'}>Fulfilled</MenuItem>
                <MenuItem value={'rejected'}>Rejected</MenuItem>
                <MenuItem value={'held'}>Held</MenuItem>
                <MenuItem value={'pending'}>Pending</MenuItem>
              </Select>
            </FormControl>
            {/* <Button variant="outlined" size="small">
              Small
            </Button> */}
            {/* <FormControl className={classes.selectForm} fullWidth>
              <Select default as ct
                value={status}
                onChange={handleChange}
                displayEmpty
                className={classes.selectInput}
              >
                <MenuItem value="">
                  <em>Status</em>
                </MenuItem>
                <MenuItem value={10}>Active</MenuItem>
                <MenuItem value={20}>Pending</MenuItem>
                <MenuItem value={30}>Disable</MenuItem>
              </Select>
            </FormControl> */}
          </Stack>
          {/* <Button className={classes.btn} endIcon={<Iconify icon="ic:round-filter-list" />}>
            Filter
          </Button> */}
        </Stack>

        <OrderByDate
          columns={columns}
          rows={rows}
          rowsPerPage={rowsPerPage}
          userlist={USERLIST}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Container>
    </Page>
  );
}
