/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Card, Stack, Typography, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { fDateTime } from '../../../utils/formatTime';
import Profile from '../../../components/Profile';
import { fCurrency } from '../../../utils/formatNumber';

export default function UserProfile() {
  const { selectedUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('coastuser'));
  console.log(user);

  if (user.role === 'sub-agent') {
    return (
      <Card sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography>Nothing to see here...</Typography>
      </Card>
    );
  }

  return (
    <Profile>
      <Card sx={{ p: 5 }}>
        <Typography> </Typography>
        <Grid
          container
          rowSpacing={1}
          justifyContent={'center'}
          alignItems={'flex-start'}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mt: 5 }}
        >
          <Grid item xs={4}>
            <Stack justifyContent={'center'} alignItems={'center'} sx={{ mt: 0 }}>
              <Box
                component="img"
                alt="profile"
                src={selectedUser?.avatar ?? 'https://www.meme-arsenal.com/memes/dc69087c94cb6ec44f899407d77a2839.jpg'}
                sx={{ borderRadius: '5%' }}
              />
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
              Profile Information for: {`${selectedUser?.first_name} ${selectedUser?.last_name}`}
            </Typography>
            <Box sx={{ mb: 3 }} />

            <Stack
              flexDirection={'row'}
              columnGap={12}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              sx={{ mb: '8px' }}
            >
              <Box>
                <Typography variant="subtitle1">Full Name</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Email Address</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Role</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">User tag</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Date joined</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Status</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Phone Number</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Referral Token</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Total Giftcard Orders Traded</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Total Rejected Orders</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Total Orders On Hold</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Total Orders Still Pending</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Total Successful Orders</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Has Verified Account</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Withdrawing Money</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography variant="subtitle1">Wallet Balance</Typography>
                <Box sx={{ mb: 3 }} />
              </Box>
              <Box>
                <Typography>{`${selectedUser?.first_name} ${selectedUser?.last_name}`}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{selectedUser?.email}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{`${selectedUser?.role.toUpperCase()}`}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{selectedUser?.user_tag}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{new Date(selectedUser?.date_registered).toLocaleString()}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography sx={{ color: selectedUser?.disabled ? 'text.danger' : 'text.success', fontWeight: 'bold' }}>
                  {selectedUser?.disabled ? 'Disabled' : 'Active'}
                </Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{selectedUser?.phone_number}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{selectedUser?.referral_token}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography sx={{ color: 'text.info' }}>{selectedUser?.totalOrders}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography sx={{ color: 'text.danger' }}>{selectedUser?.rejected_trade_count}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography sx={{ color: 'text.warning' }}>{selectedUser?.totalOrdersPlacedOnHold}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography sx={{ color: 'text.warning' }}>{selectedUser?.totalPendingOrders}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography sx={{ color: 'text.success' }}>{selectedUser?.totalSuccessfulOrders}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{selectedUser?.verified ? 'Yes, account verified' : 'No'}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{selectedUser?.withdrawal_in_progress ? 'Yes' : 'No'}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography sx={{ color: 'text.success', fontWeight: 'bold' }}>
                  {`${fCurrency(selectedUser?.wallet_balance)} Naira`}
                </Typography>
                <Box sx={{ mb: 3 }} />
              </Box>
            </Stack>
            <Divider />
            <Typography sx={{ fontSize: '22px', fontWeight: 'bold' }}>Bank Account Details</Typography>
            {!selectedUser?.accounts.length ? (
              <>
                <Typography sx={{ fontSize: '18px', fontWeight: '400', fontStyle: 'italic', color: 'text.warning' }}>
                  User has not added any bank account yet
                </Typography>
                <Box sx={{ mb: 3 }} />
              </>
            ) : (
              selectedUser?.accounts.map(
                ({ account_id, account_name, account_number, bank_name, created_on }, index) => (
                  <>
                    <Box sx={{ mb: 3 }} />
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{`Account #${index + 1}`}</Typography>
                    <Box sx={{ mb: 2 }} />
                    <Stack
                      flexDirection={'row'}
                      columnGap={12}
                      justifyContent={'flex-start'}
                      alignItems={'flex-start'}
                      sx={{ mb: '8px' }}
                    >
                      <Box>
                        <Typography variant="subtitle1">Bank Name</Typography>
                        <Box sx={{ mb: 3 }} />
                        <Typography variant="subtitle1">Account Number</Typography>
                        <Box sx={{ mb: 3 }} />
                        <Typography variant="subtitle1">Account Name</Typography>
                        <Box sx={{ mb: 3 }} />
                        <Typography variant="subtitle1">Date Added</Typography>
                        <Box sx={{ mb: 3 }} />
                      </Box>
                      <Box>
                        <Typography>{bank_name}</Typography>
                        <Box sx={{ mb: 3 }} />
                        <Typography>{account_number}</Typography>
                        <Box sx={{ mb: 3 }} />
                        <Typography>{account_name}</Typography>
                        <Box sx={{ mb: 3 }} />
                        <Typography>{new Date(created_on).toLocaleString()}</Typography>
                        <Box sx={{ mb: 3 }} />
                      </Box>
                    </Stack>
                  </>
                )
              )
            )}
            <Divider />
            <LoadingButton size="large" variant="contained" onClick={() => navigate(-1)}>
              Back to users
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
    </Profile>
  );
}
