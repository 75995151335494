/* eslint-disable react/jsx-key */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Grid,
  Button,
  Typography,
  TextField,
  Tab,
  Box,
  Paper,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  Stack,
  Container,
  List,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as yup from 'yup';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import { makeStyles } from '@material-ui/core';
import Page from '../components/Page';
import Physical from '../sections/@dashboard/asset/Physical';
import * as GiftcardActions from '../store/actions/giftcard';
import * as RateActions from '../store/actions/rates';
import Scrollbar from '../components/Scrollbar';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    background: 'transparent',

    '&:hover': {
      color: '#fff',
      background: '#0655FF',
    },
    '&:focus': {
      background: '#0655FF',
    },
  },
  iconFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '12%',
  },
}));

export default function AddRate() {
  const [value, setValue] = React.useState('1');

  const [rangeName] = React.useState();
  const [countryName] = React.useState();
  const [rateName, setRateName] = React.useState();
  const [giftName, setGiftName] = React.useState();
  const [selectedGift, setSelectedGift] = React.useState();
  const [selectedRange, setSelectedRange] = React.useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { giftcards, giftcardCountries, giftcardTypes, giftcardRanges, currentGiftcard } = useSelector(
    (state) => state.giftcard
  );
  const { rates } = useSelector((state) => state?.rates);
  const { user } = useSelector((state) => state.user);
  const ratesToShow = rates?.sort(
    (rat1, rat2) => Number(new Date(rat2.created_on)) - Number(new Date(rat1.created_on))
  );

  React.useEffect(() => {
    dispatch(GiftcardActions.getGiftcardRanges());
    dispatch(GiftcardActions.getGiftcardCountries());
    dispatch(RateActions.getAllRates());
  }, []);

  const handleValueChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleGiftcardChange = (event) => {
    dispatch(GiftcardActions.setSelectedGiftcard(event.target.value));
    dispatch(GiftcardActions.getGiftcardTypes(event.target.value));
  };

  const classes = useStyles();

  const createRate = async ({ giftcard, type, country, range, rate }) => {
    // if (user.role !== 'admin') {
    //   return Swal.fire({
    //     icon: 'error',
    //     title: 'Forbidden!',
    //     text: 'You are forbidden from performing this operation!',
    //     buttonsStyling: true,
    //     didClose: () => navigate('/dashboard/app'),
    //   });
    // }
    try {
      const data = {
        giftcard,
        type,
        country,
        range,
        rate: String(rate),
      };
      console.log(data);
      dispatch(RateActions.createRate(data));
      Swal.fire({
        icon: 'success',
        title: 'Rate Created',
        text: 'New Giftcard Rate has been added successfully',
        didClose: () => dispatch(RateActions.getAllRates()),
        buttonsStyling: true,
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Rate Not Created',
        text: 'Rate already exists or network error',
        buttonsStyling: true,
      });
    }
  };

  const rateSchema = yup.object().shape({
    giftcard: yup.string().required('giftCard required'),
    type: yup.string().required('type required'),
    country: yup.string().required('country required'),
    range: yup.string().required('range required'),
    rate: yup.number().required('rate required'),
  });

  const formik = useFormik({
    initialValues: {
      giftcard: '',
      type: '',
      range: '',
      country: '',
      rate: '',
    },

    onSubmit: (values) => {
      createRate(values);
      // resetForm({ values: {} });
    },
    validationSchema: rateSchema,
  });

  return (
    <Page title="Rates">
      <Container>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Paper sx={{ p: 5, color: 'text.main' }}>
              <Paper sx={{ p: 5, boxShadow: 12, mb: '12px' }} className={classes.paper}>
                <Typography sx={{ fontSize: '24px', fontWeight: '800' }}>{currentGiftcard?.name}</Typography>
                <Box sx={{ mb: 2 }} />
                <Box sx={{ width: '100%', height: '100%' }}>
                  <Box
                    component="img"
                    src={`${
                      currentGiftcard.image
                        ? currentGiftcard.image
                        : 'https://images.pexels.com/photos/1303084/pexels-photo-1303084.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                    }`}
                    alt={currentGiftcard?.name}
                    sx={{ width: '100%', height: '100%', mb: '30px', borderRadius: '5%' }}
                  />
                </Box>
              </Paper>
              <Box sx={{ mb: 2 }} />
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                  <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
                    <Typography>Select Gift Card</Typography>
                    <Select
                      value={formik.values.giftcard}
                      type="text"
                      id="giftcard"
                      name="giftcard"
                      onChange={(e) => {
                        handleGiftcardChange(e);
                        formik.handleChange(e);
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>Select Giftcard</em>
                      </MenuItem>
                      {giftcards?.map((giftcard) => (
                        <MenuItem key={giftcard?.id} name="giftcard" value={giftcard?.id}>
                          {giftcard?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '& > :not(style)': { m: 1 },
                    }}
                  >
                    <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
                      <Typography>Range</Typography>
                      <Select
                        value={formik.values.range}
                        type="text"
                        id="range"
                        name="range"
                        onChange={formik.handleChange}
                      >
                        <MenuItem disabled value="">
                          <em>Select a range</em>
                        </MenuItem>
                        {giftcardRanges?.map((range) => (
                          <MenuItem key={range?.id} name="range" value={range?.id}>
                            {range.range}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
                      <Typography>Country</Typography>
                      <Select
                        value={formik.values.country}
                        type="text"
                        name="country"
                        id="country"
                        onChange={formik.handleChange}
                        input={<OutlinedInput />}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem disabled value="">
                          <em>Select Country</em>
                        </MenuItem>
                        {giftcardCountries?.map((country) => (
                          <MenuItem key={country.id} name="country" value={country.id}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
                    <Typography>Type</Typography>
                    <Select value={formik.values.type} type="text" id="type" name="type" onChange={formik.handleChange}>
                      <MenuItem disabled value="">
                        <em>Select a type</em>
                      </MenuItem>
                      {giftcardTypes?.map((type) => (
                        <MenuItem key={type?.id} name="type" value={type?.id}>
                          {type.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: '100%' }}>
                    <Typography>Rate</Typography>
                    <TextField
                      // onBlur={formik.handleBlur}
                      id="rate"
                      name="rate"
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.rate}
                      label="Enter Rate"
                    />
                  </FormControl>

                  <Button variant="outlined" type="submit" sx={{ width: '100%', mt: '30px' }}>
                    Create GiftCard Rate
                  </Button>
                </Form>
              </FormikProvider>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper sx={{ p: 5, color: 'text.main' }} elevation={2}>
              <Box sx={{ width: '100%', mb: 2 }}>
                <Typography align="center" color="#777" variant="h4">
                  Current Rates
                </Typography>
              </Box>
              <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                <Box sx={{ width: '100%' }}>
                  <Typography color="inherit" variant="h6">
                    Giftcard Name
                  </Typography>
                </Box>

                <Box sx={{ width: '100%' }}>
                  <Typography color="inherit" variant="h6">
                    Country
                  </Typography>
                </Box>

                <Box sx={{ width: '100%' }}>
                  <Typography color="inherit" variant="h6">
                    Range
                  </Typography>
                </Box>

                <Box sx={{ width: '100%' }}>
                  <Typography color="inherit" variant="h6">
                    Rate
                  </Typography>
                </Box>

                <Box sx={{ width: '100%' }}>
                  <Typography color="inherit" variant="h6">
                    Type
                  </Typography>
                </Box>

                <Box sx={{ width: '100%' }}>
                  <Typography color="inherit" variant="h6">
                    Action
                  </Typography>
                </Box>
              </Stack>
              {ratesToShow?.map((rate) => (
                <>
                  <Stack key={`${rate.id} ${rate.range}`} direction="row" alignItems="center" spacing={2}>
                    <Physical rate={rate} />
                    <Box sx={{ mb: 10 }} />
                  </Stack>
                </>
              ))}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
