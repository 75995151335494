/* eslint-disable react/prop-types */
import { useRef, useState } from 'react';
import { Box, Card, Stack, Typography, IconButton, Tab, Divider, useMediaQuery } from '@mui/material';
import Iconify from '../../../components/Iconify';
import GiftCardMoreMenu from './GiftCardMore';

export default function GiftCardsCard({ gift }) {
  return (
    <Card sx={{ mb: '18px' }}>
      <Stack flexDirection={'row'} justifyContent={'end'}>
        <GiftCardMoreMenu giftId={gift.id} />
      </Stack>

      <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'} pt={8}>
        <Box sx={{ width: '100px', height: '100px' }}>
          <Box
            component="img"
            src={gift.image}
            alt={`${gift.id}`}
            sx={{ width: '100%', height: '100%', borderRadius: '50%', mb: '30px' }}
          />
        </Box>

        <Box sx={{ pt: '40px', pb: '22px', color: 'text.main', fontWeight: '700' }}>{gift.name}</Box>
      </Stack>
    </Card>
  );
}
