/* eslint-disable no-else-return */
/* eslint-disable no-useless-catch */
import client from './index';

const instance = client(true);

export async function sendNotifications(data) {
  try {
    const { status } = await instance.post(`/user/broadcast-email`, data);
    if (status !== 200) {
      throw new Error();
    }
  } catch (error) {
    throw error;
  }
}
