/* eslint-disable no-else-return */
/* eslint-disable no-useless-catch */
import client from './index';

const instance = client(true);

export async function getAllRates() {
  try {
    const { data, status } = await instance.get(`/giftcard/rates`);
    if (status === 200) {
      return data?.data?.giftcardRates;
    } else throw new Error();
  } catch (error) {
    throw error;
  }
}

export async function deleteRate(rateId) {
  await instance.delete(`/giftcard/rates/${+rateId}`);
}

export async function createRate(data) {
  try {
    const { _, status } = await instance.post(`/giftcard/rates`, data);
    if (status !== 200) {
      throw new Error();
    }
  } catch (error) {
    throw error;
  }
}

export async function updateRate(newRate, rateId) {
  const data = {
    rate: newRate,
  };
  try {
    const { _, status } = await instance.patch(`/giftcard/updateGiftcardRate/${rateId}`, data);
    if (status !== 200) {
      throw new Error();
    }
  } catch (error) {
    throw error;
  }
}
