/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import { Container } from '@mui/material';
import Page from './Page';

const Profile = forwardRef(({ children }, ref) => (
  <Page>
    <Container>{children}</Container>
  </Page>
));
export default Profile;
