import { Navigate, Route, Routes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import GetToken from './pages/GetToken';
import DashboardApp from './pages/DashboardApp';
import Assets from './pages/Asset';
import Orders from './pages/Orders';
import Statistics from './pages/Statistics';
import Setting from './pages/Setting';
import AdminProfile from './sections/@dashboard/admin/AdminProfile';
import Rates from './pages/Rates';
import UserProfile from './sections/@dashboard/user/UserProfile';
import ForgotPassword from './pages/ForgotPassword';
import VerifyToken from './pages/VerifyToken';
import Notifications from './pages/Notifications';

// ----------------------------------------------------------------------

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="get-token" element={<GetToken />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="verify-token" element={<VerifyToken />} />

      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route path="app" element={<DashboardApp />} />
        <Route path="users">
          <Route index path="" element={<User />} />
          <Route path=":userId" element={<UserProfile />} />
        </Route>
        <Route path="assets" element={<Assets />} />
        <Route path="rates" element={<Rates />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="orders" element={<Orders />} />
        <Route path="statistics" element={<Statistics />} />
        <Route path="settings" element={<Setting />} />
        <Route path="admin-profile" element={<AdminProfile />} />
      </Route>
    </Routes>
  );
  // return useRoutes([
  //   {
  //     path: '/dashboard',
  //     element: <DashboardLayout />,
  //     children: [
  //       { path: 'app', element: <DashboardApp /> },
  //       { path: 'users', element: <User /> },
  //       { path: 'assets', element: <Assets /> },
  //       { path: 'add-rate', element: <AddRate /> },
  //       { path: 'orders', element: <Orders /> },
  //       { path: 'statistics', element: <Statistics /> },
  //       { path: 'settings', element: <Setting /> },
  //       { path: 'admin-profile', element: <AdminProfile /> },
  //     ],
  //   },
  //   {
  //     path: '/',
  //     element: <LogoOnlyLayout />,
  //     children: [
  //       { path: '/', element: <Navigate to="/dashboard/app" /> },
  //       { path: 'login', element: <Login /> },
  //       { path: 'register', element: <Register /> },
  //       { path: 'get-token', element: <GetToken /> },
  //       { path: '404', element: <NotFound /> },
  //       { path: '*', element: <Navigate to="/404" /> },
  //     ],
  //   },
  //   { path: '*', element: <Navigate to="/404" replace /> },
  // ]);
}
