import client from './index';

const instance = client();

export default class AuthCalls {
  static async login(username, password) {
    const data = {
      username,
      password,
    };
    if (username.includes('@')) {
      data.username = undefined;
      data.email = username;
    }
    const { data: user, status } = await instance.post('/auth/login', data);
    if (status !== 200) {
      const error = new Error('unable to login');
      error.status = 400;
      throw error;
    }
    return user;
  }

  static async verifyToken(token) {
    const body = {
      token,
    };
    const { data, status } = await instance.post('/auth/verifyAdminLogin', body);
    if (status !== 200) {
      const error = new Error('unable to login');
      error.status = 400;
      throw error;
    }
    return {
      token: data.data.token,
      user: data.data.user,
    };
  }

  static async resetPassword(email) {
    const { data, status } = await instance.patch(`/auth/forgotPassword/${email}`);
    if (status !== 200) {
      const error = new Error('unable to send password reset token');
      error.status = 400;
      throw error;
    }
    return data;
  }

  static async verifyPasswordResetToken(data) {
    const { data: result, status } = await instance.patch(`/auth/resetPassword`, data);
    if (status !== 200) {
      const error = new Error('unable to send password reset token');
      error.status = 400;
      throw error;
    }
    return result;
  }
}
