// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Stack, Box } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: 'center',
}));

export default function AppWidgetSummary({ title, total, icon, color = 'primary', sx, cursor, ...other }) {
  return (
    <Card
      sx={{
        py: 2,
        px: 3,
        boxShadow: 0,
        textAlign: 'center',
        cursor: cursor && 'pointer',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <Stack direction="row" width={'100%'} spacing={2} justifyContent="center" alignItems="center" alignSelf="center">
        <IconWrapperStyle
          sx={{
            color: (theme) => theme.palette[color].dark,
            backgroundImage: (theme) =>
              `linear-gradient(105deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
                theme.palette[color].dark,
                0.24
              )} 100%)`,
          }}
        >
          <Iconify icon={icon} width={24} height={24} />
        </IconWrapperStyle>

        <Box>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            {title}
          </Typography>
          <Typography variant="h5">{total}</Typography>
        </Box>
      </Stack>
    </Card>
  );
}
