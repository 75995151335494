import * as Yup from 'yup';
import React, { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import axios from 'axios';
// material
import { Stack, TextField, Button, Typography, IconButton, InputAdornment } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
//
import * as AuthActions from '../../../store/actions/user';
import AuthCalls from '../../../api/auth';

export default function VerifyTokenForm() {
  const [errorMsg, setErrorMsg] = useState('');
  const [next, setNext] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const user = useSelector((state) => state.user);

  const handleTokenVerification = async (values) => {
    try {
      const data = {
        password: values.password,
        confirmPassword: values.confirmPassword,
        token: values.token,
      };
      await AuthCalls.verifyPasswordResetToken(data);
      Swal.fire({
        icon: 'success',
        title: 'Password Reset',
        text: 'password has successfully been reset. proceed to login with the new password',
      });
      navigate('/', { replace: true });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Invalid Token',
        text: 'Invalid token or token has expired',
      });
    }
  };

  React.useEffect(() => {
    dispatch(AuthActions.resetLogin());
  }, []);

  const verifyTokenSchema = Yup.object().shape({
    password: Yup.string().required('password is required'),
    confirmPassword: Yup.string()
      .required('confirmPassword is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    token: Yup.string().required('Token is required'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      token: '',
    },
    validationSchema: verifyTokenSchema,
    onSubmit: (values) => {
      handleTokenVerification(values);
    },
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            type="token"
            shrink
            label="Enter token"
            {...getFieldProps('token')}
            error={Boolean(touched.token && errors.token)}
            helperText={touched.token && errors.token}
          />
          <TextField
            fullWidth
            shrink
            label="Password"
            type={showPassword ? 'text' : 'password'}
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            shrink
            label="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            {...getFieldProps('confirmPassword')}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowConfirmPassword} edge="end">
                    <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button fullWidth size="large" type="submit" variant="contained" onClick={() => handleTokenVerification}>
            Reset Password
          </Button>
          {errorMsg && <Typography sx={{ color: 'text.danger' }}>{errorMsg}</Typography>}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
