// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAOVqKcY9XUfHn-H7h6T8r_s73ujxB2Juw',
  authDomain: 'coastapp-c43d5.firebaseapp.com',
  projectId: 'coastapp-c43d5',
  storageBucket: 'coastapp-c43d5.appspot.com',
  messagingSenderId: '821319675751',
  appId: '1:821319675751:web:08ed5767b69466a6b309c2',
  measurementId: 'G-3VY46M2MCV',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export function getDeviceToken(setClientToken) {
  return getToken(messaging, {
    vapidKey: 'BHSmpAkDHSu7uWa70Td7wrC9dck-xRfmMS5S4AGjO7oWbrIweMMznpxrjB3oUYkS3tv18VH44THlVgDWgBwoyhc',
  })
    .then((currentToken) => {
      if (currentToken) {
        setClientToken(currentToken);
      } else {
        setClientToken(null);
      }
    })
    .catch((err) => {});
}

export function onMessageListener() {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
}
