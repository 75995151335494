/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  Card,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Container,
} from '@mui/material';
import Scrollbar from '../../../components/Scrollbar';
// import OrderDetails from '../app/OrderDetails';
import OrderDetailsBox from '../app/OrderDetailsBox';
import Page from '../../../components/Page';

const columns = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'value', label: 'Value', alignRight: false },
  { id: 'rate', label: 'Rate', alignRight: false },
  { id: 'amount', label: 'Amount(#)', alignRight: false },
  { id: 'agent', label: 'Attended By', alignRight: false },
  { id: 'userTag', label: 'Traded By', alignRight: false },
  { id: 'time', label: 'Date Traded', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

function createData(name, value, rate, amount, agent, time, status) {
  return { name, value, rate, amount, agent, time, status };
}

class Order {
  constructor(order) {
    this.id = order.order_id;
    this.name = order.name;
    this.value = order.value;
    this.rate = order.rate;
    this.amount = order.amount_in_naira;
    this.attendedBy = order.attended_by;
    this.date = new Date(order.traded_on).toLocaleString();
    this.status = order.status;
    this.userTag = order.user_tag;
  }
}

export default function OrderByDate({ userlist, page, handleChangePage, handleChangeRowsPerPage, rowsPerPage }) {
  const orders = useSelector((state) => state.trade.giftcardOrders);
  const rows = orders.map((order) => new Order(order));
  const [show, setShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  function showMoreOrderDetails(orderId) {
    const order = orders.find((order) => order.order_id === orderId);
    setSelectedOrder(order);
    setShow(true);
  }

  const handleCloseDetails = () => {
    setShow(false);
  };

  return (
    <Page title="Dashboard">
      <Typography sx={{ fontSize: 18, p: 2 }} color="text.secondary" gutterBottom>
        All Orders
      </Typography>
      <Container maxWidth="xl">
        <Grid container spacing={3} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
          <Grid item xs={12} md={show ? 6 : 12} lg={show ? 6 : 12}>
            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => showMoreOrderDetails(row.id)}
                        >
                          <TableCell component="th" scope="row" sx={{ px: 2 }}>
                            <Typography noWrap>{row.name}</Typography>
                          </TableCell>
                          <TableCell align="left">{row.value}</TableCell>
                          <TableCell align="left">{row.rate}</TableCell>
                          <TableCell align="left">{row.amount}</TableCell>
                          <TableCell align="left">{row.attendedBy ?? 'A Ghost'}</TableCell>
                          <TableCell align="left">{row.userTag ?? 'A Ghost'}</TableCell>
                          <TableCell align="left">{row.date}</TableCell>
                          <TableCell align="left">
                            <Typography
                              sx={{
                                color:
                                  row.status === 'fulfilled'
                                    ? 'text.success'
                                    : row.status === 'rejected'
                                    ? 'text.danger'
                                    : 'text.warning',
                                fontStyle: 'italic',
                              }}
                            >
                              {row.status}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Grid>
          {show && (
            <Grid item xs={12} md={6} lg={6}>
              <Card sx={{ p: 1, mb: 4 }}>
                <OrderDetailsBox
                  show={show}
                  setShow={setShow}
                  handleCloseDetails={handleCloseDetails}
                  selectedOrder={selectedOrder}
                />
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
