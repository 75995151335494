/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-arrow-callback */
import axios from 'axios';

export const rootUrl = 'https://api.coast.ng/api/v1';

function client(auth, contentType) {
  const defaultOptions = {
    baseURL: rootUrl,
    method: 'get',
    headers: {
      'Content-Type': contentType || 'application/json',
      'X-Coast-App-Platform': 'dashboard',
    },
    timeout: 50000,
  };

  const instance = axios.create(defaultOptions);

  auth &&
    instance.interceptors.request.use(function (config) {
      const token = JSON.parse(localStorage.getItem('coastauth'));
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      return config;
    });

  return instance;
}

export default client;
