import React from 'react';
import Swal from 'sweetalert2';
import Favicon from 'react-favicon';
import { ToastContainer, toast } from 'react-toastify';
import { faker } from '@faker-js/faker';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { set, sub, format } from 'date-fns';
import { getDeviceToken, onMessageListener } from './firebase';
import 'react-toastify/dist/ReactToastify.css';
// import NotificationContext from './context/notification';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import * as AuthActions from './store/actions/user';

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clientToken, setClientToken] = React.useState(null);
  // const [notification, setNotification] = React.useState({ title: '', body: '' });
  // const [notifications, setNotifications] = React.useState([]);

  onMessageListener()
    .then((payload) => {
      // setNotification({ title: payload.notification.title, body: payload.notification.body });
      // setNotifications((notifications) =>
      //   notifications.unshift({
      //     id: faker.datatype.uuid(),
      //     title: payload.notification.title,
      //     body: payload.notification.body,
      //     avatar: null,
      //     type: 'order_placed',
      //     createdAt: new Date(Date.now()).toLocaleString(),
      //     isUnRead: true,
      //   })
      // );
      const options = {
        autoClose: 5000,
        closeButton: 'X',
        hideProgressBar: false,
        position: toast.POSITION.TOP_RIGHT,
        pauseOnHover: true,
        type: toast.TYPE.INFO,
        progress: 1.0,
        pauseOnFocusLoss: true,
        delay: 5000,
      };
      const audio = new Audio('https://nf1f8200-a.akamaihd.net/downloads/ringtones/files/mp3/loud-alarm-02-46010.mp3');
      toast(
        `${payload.notification.title},
        ${payload.notification.body}
      `,
        options
      );
      audio.play();
    })
    .catch((err) => console.log('failed!'));

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem('coastuser'));
    const authToken = JSON.parse(localStorage.getItem('coastauth'));
    if (!user || !authToken) {
      return navigate('/', { replace: true });
    }
    dispatch(AuthActions.setUser(user));
    dispatch(AuthActions.setAuthToken(authToken));
    navigate('/dashboard/app', { replace: true });
  }, []);

  React.useEffect(() => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then((result) => {
        if (result !== 'granted') {
          Swal.fire({
            icon: 'info',
            title: 'Notification Permission Required',
            text: 'You need to allow notification on your browser in order to receive notifications when a giftcard order is being placed. Go to Chrome settings, privacy and security then allow notification in your browser or reset notification permissions',
            buttonsStyling: true,
          });
        }
      });
    }
    getDeviceToken(setClientToken);
    if (clientToken) {
      dispatch(AuthActions.setClientToken(clientToken));
    }
  }, [clientToken]);

  return (
    // <NotificationContext.Provider
    //   value={{
    //     notifications,
    //     setNotifications,
    //   }}
    // >
    <ThemeProvider>
      <Favicon url="https://lh3.googleusercontent.com/a-/AFdZucrkI2X4coAJK2iMEzAVvwdNPZuvS5oLLly-zHst=s40-p" />
      <BaseOptionChartStyle />
      <Router />
      <ToastContainer />
    </ThemeProvider>
    // </NotificationContext.Provider>
  );
}
