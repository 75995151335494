/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  Select,
  FormControl,
  FormHelperText,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';
import FileUpload from 'react-material-file-upload';

import Iconify from '../../../components/Iconify';
import * as TradeActions from '../../../store/actions/trade';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
}));

const RejectOrder = ({ open, handleRejectClose, fullScreen, order }) => {
  const [reason, setReason] = useState('');
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { rejectedReasons } = useSelector((state) => state.trade);

  const handleChange = (event) => {
    setReason(event.target.value);
  };

  function handleSubmit() {
    if (reason === '') {
      handleRejectClose();
      return Swal.fire({
        title: 'Validation Failed',
        text: 'Rejected reason needs to be selected',
        icon: 'error',
      });
    }
    const formData = new FormData();
    formData.append('reason', reason);
    files.forEach((file) => formData.append('screenshots', file, file.name));
    dispatch(TradeActions.attendToGiftcardOrder('reject', order.orderId, formData));
    handleRejectClose();
    Swal.fire({
      title: 'Giftcard Rejected',
      text: 'giftcard order has been rejected successfully',
      icon: 'success',
    });
    navigate('/dashboard/app');
  }

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleRejectClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          display: 'block',
          margin: '0 auto',
        }}
      >
        <DialogContent>
          <IconWrapperStyle>
            <Iconify icon="ant-design:close-circle-outlined" width={'50%'} height={'50%'} color={'text.info'} />
          </IconWrapperStyle>
          <DialogContentText mt={2}>
            Order Rejected!
            <FormControl sx={{ mt: 1, minWidth: 200 }} fullWidth>
              <FormHelperText>Reason(s) for rejecting this order</FormHelperText>
              <Box sx={{ mb: 2 }} />
              <Select
                value={reason}
                onChange={handleChange}
                displayEmpty
                inputProps={{
                  'aria-label': 'Without label',
                }}
              >
                <MenuItem value="">
                  <em>Select Reason</em>
                </MenuItem>
                {rejectedReasons.map((reason) => (
                  <MenuItem key={reason.id} value={reason.id}>
                    {reason.reason}
                  </MenuItem>
                ))}
              </Select>
              <Box mb={3} />
            </FormControl>
            <FileUpload value={files} onChange={setFiles} title="Drag and Drop Screenshots" maxFiles={10} multiple />
          </DialogContentText>
        </DialogContent>
        <Box p={2}>
          <Button sx={{ width: '100%' }} variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default RejectOrder;
