import React, { useEffect } from 'react';
import GiftCardsLists from './GiftCardLists';

function GiftCards({ gifts }) {
  // pass giftcards list from store to the component below GiftCardsLists
  return (
    <>
      <GiftCardsLists gifts={gifts} />
    </>
  );
}

export default GiftCards;
