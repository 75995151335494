/* eslint-disable no-else-return */
/* eslint-disable no-useless-catch */
import client from './index';

const instance = client(true);

export default class TradeCalls {
  static async getGiftcardOrders() {
    const { data, status } = await instance.get(`/orders/giftcard`);
    return data.data.giftcards;
  }

  static async getGiftcardReasons(type) {
    const { data, status } = await instance.get(`/reasons?type=${type}`);
    return data.data;
  }

  static async attendToGiftcardOrder(action, orderId, formData) {
    if (String(action).includes('reject')) {
      const instance2 = client(true, 'multipart/form-data');
      const url = `/orders/attend/${orderId}/${action}`;
      const { data, status } = await instance2.patch(url, formData);
      return status === 200;
    } else if (String(action).includes('hold')) {
      const dataToSend = {
        reason: formData,
      };
      const url = `/orders/attend/${orderId}/${action}`;
      const { data: returnedData, status } = await instance.patch(url, dataToSend);
      return status === 200;
    } else {
      const url = `/orders/attend/${orderId}/${action}`;
      const { data: returnedData, status } = await instance.patch(url);
      return status === 200;
    }
  }
}
