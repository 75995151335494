/* eslint-disable prefer-const */
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
// material
import { Menu, MenuItem, IconButton, ListItemText, useMediaQuery } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import DeleteDialog from './DeleteDialog';
import AddGiftCard from './AddGiftCard';
import * as GiftcardActions from '../../../store/actions/giftcard';

// ----------------------------------------------------------------------

export default function GiftCardMoreMenu({ giftId }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();

  const deleteOpen = () => {
    setOpenDelete(true);
  };
  const deleteClose = () => {
    setOpenDelete(false);
  };

  const addOpen = () => {
    setOpenAdd(true);
  };
  const addClose = () => {
    setOpenAdd(false);
  };

  const deleteCard = async () => {
    dispatch(GiftcardActions.deleteGiftcard(giftId));
    Swal.fire({
      icon: 'success',
      title: 'Giftcard Deleted',
      text: 'Giftcard deleted successfully',
    });
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-horizontal-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem componentnuItem={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemText onClick={deleteOpen} primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
      {/* <AddGiftCard open={openAdd} addGiftClose={addClose} fullScreen={fullScreen} /> */}
      <DeleteDialog deleteCard={deleteCard} open={openDelete} deleteClose={deleteClose} fullScreen={fullScreen} />
    </>
  );
}
