import React from 'react'
import { Box,Card, Typography, Grid, Container } from '@mui/material';
import Page from '../components/Page';
import { AccountSetting, ChangePassword } from '../sections/@dashboard/setting';


export default function Settings(){
    return (
        <Page title="Setting">
            <Container>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3, }} sx={{ mt: 5}}>
        
        <Grid item xs={6}>
                <AccountSetting/>
        </Grid>
        <Grid item xs={6}>
                <ChangePassword/>
        </Grid>
        </Grid>
            </Container>
        </Page>

    )
}