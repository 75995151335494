/* eslint-disable no-empty-function */
/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable max-classes-per-file */

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// @mui
import { makeStyles } from '@material-ui/core';
import {
  Grid,
  Container,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  Card,
  Table,
  Stack,
  TableHead,
  TableContainer,
  TablePagination,
  Paper,
  Box,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Circle } from '@mui/icons-material';
// components
import Page from '../components/Page';
import DashboardTable from '../sections/@dashboard/app/DashboardTable';
// sections
import { AppWidgetSummary, RecentTransaction } from '../sections/@dashboard/app';
import Scrollbar from '../components/Scrollbar';

// mock
import Agents from '../sections/@dashboard/app/Agents';
import OrderDetailsBox from '../sections/@dashboard/app/OrderDetailsBox';
import * as StatisticsActions from '../store/actions/statistics';
import * as TradeActions from '../store/actions/trade';
import * as GiftcardActions from '../store/actions/giftcard';
import * as UserActions from '../store/actions/user';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'value', label: 'Value', minWidth: 100 },
  {
    id: 'rate',
    label: 'Rate',
    minWidth: 170,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
  },
];

class GiftcardOrder {
  constructor({ name, value, rate, status, order_id }) {
    this.name = name;
    this.value = value;
    this.rate = rate;
    this.status = status;
    this.orderId = order_id;
  }
}

class RecentOrder {
  constructor(order) {
    this.name = `${order.name}`;
    this.attendedBy = order.attended_by;
    this.status = order.status;
    this.amount = `#${order.value}`;
  }
}

class TopAgents {
  constructor() {}
}

export default function DashboardApp() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    totalRevenue: revenue,
    totalOrders: orders,
    totalFulfilledOrders: fulfilledOrders,
    totalUsers: users,
    topAgents,
  } = useSelector((state) => state.statistics);
  const { giftcardOrders } = useSelector((state) => state.trade);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(GiftcardActions.getGiftcards());
    dispatch(StatisticsActions.getTotalRevenue());
    dispatch(StatisticsActions.getTotalOrders());
    dispatch(StatisticsActions.getTotalOrders('fulfilled'));
    dispatch(StatisticsActions.getTotalUsers());
    dispatch(StatisticsActions.getTopAgents());
    dispatch(TradeActions.getGiftcardOrders());
    dispatch(TradeActions.setGiftcardReasons('rejected'));
    dispatch(TradeActions.setGiftcardReasons('held'));
    dispatch(UserActions.setUsers());
    dispatch(UserActions.setTopUsers());
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [show, setShow] = useState(false);

  const useStyles = makeStyles({
    tableCell: {
      borderBottom: 'none',
    },
  });

  const handleDetails = (orderId) => {
    dispatch(TradeActions.setCurrentOrder(orderId));
    setShow(true);
  };

  const handleCloseDetails = () => {
    setShow(false);
  };

  const classes = useStyles();

  const transactions = giftcardOrders
    .sort((ord1, ord2) => Number(new Date(ord2.traded_on) - Number(new Date(ord1.traded_on))))
    .map((ord) => new RecentOrder(ord))
    .slice(0, 5);

  const agents = [
    { name: 'Adedayo Pelumi', image: `/static/mock-images/avatars/avatar_24.jpg`, amount: '500' },
    { name: 'Adedayo Pelumi', image: `/static/mock-images/avatars/avatar_25.png`, amount: '200' },
    { name: 'Adedayo Pelumi', image: `/static/mock-images/avatars/avatar_26.png`, amount: '500' },
  ];

  function handleShowFulfilledOrders() {
    Promise.resolve(navigate('/dashboard/orders')).then(() => dispatch(TradeActions.getGiftcardOrders('fulfilled')));
  }

  const ordersToRender = giftcardOrders
    .filter((ordr) => ordr.status === 'pending')
    .map((order) => new GiftcardOrder(order));
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={3} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Revenue" total={`N${revenue}`} icon={'fa6-solid:naira-sign'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Orders"
              total={`${orders}`}
              color="error"
              icon={'icomoon-free:stats-bars'}
              onClick={() => navigate('/dashboard/orders')}
              cursor
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Fulfilled Orders"
              total={`${fulfilledOrders}`}
              color="info"
              icon={'simple-icons:skypeforbusiness'}
              onClick={() => handleShowFulfilledOrders()}
              cursor
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Number of Users"
              total={`${users}`}
              color="warning"
              icon={'fa-solid:users'}
              onClick={() => navigate('/dashboard/users')}
              cursor
            />
          </Grid>
          <Box sx={{ mb: 15 }} />
          <Grid item xs={12} md={6} lg={8}>
            <Card>
              <Scrollbar>
                <Paper variant="outlined" sx={{ width: '100%' }}>
                  {/* <DataGrid rows={allGifts} columns={columns} pageSize={5} rowsPerPageOptions={[5]} checkboxSelection /> */}
                  {/* <DashboardTable columns={columns} data={data} /> */}
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table aria-label="table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ordersToRender.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                          <TableRow
                            hover
                            key={row.orderId}
                            onClick={() => handleDetails(row.orderId)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell className={classes.tableCell}>{row.name}</TableCell>
                            <TableCell className={classes.tableCell}>{`$${row.value}`}</TableCell>
                            <TableCell className={classes.tableCell}>{`#${row.rate}/$`}</TableCell>
                            <TableCell
                              sx={{
                                color: 'text.warning',
                                fontStyle: 'italic',
                              }}
                            >
                              {' '}
                              <Stack
                                direction="row"
                                spacing={2}
                                columnSpacing={{
                                  xs: 1,
                                  sm: 2,
                                  md: 3,
                                }}
                                alignItems="center"
                              >
                                <Circle
                                  sx={{
                                    fontSize: '6px',
                                  }}
                                />{' '}
                                {row.status}{' '}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={ordersToRender.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Scrollbar>
            </Card>
          </Grid>

          {!show ? (
            <Grid item xs={12} md={6} lg={4}>
              <Card sx={{ p: 1, mb: 4 }}>
                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                  Recent Transactions
                </Typography>
                {transactions.map((transaction, index) => (
                  <>
                    <RecentTransaction
                      key={`${transaction.name}` - `${index}`}
                      title="Recent Transactions"
                      name={transaction.name}
                      amount={transaction.amount}
                      attendedBy={transaction.attendedBy}
                      status={transaction.status}
                    />
                  </>
                ))}
              </Card>

              <Card sx={{ p: 1, mb: 4 }}>
                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                  Top Agents By Orders Attended To
                </Typography>
                {topAgents.map((agent, index) => (
                  <Agents
                    key={`${agent.first_name}` - `${index}`}
                    name={`${agent.first_name} ${agent.last_name}`}
                    amount={agent.total_attended_orders}
                    image={agent.avatar}
                  />
                ))}
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12} md={6} lg={4}>
              <Card sx={{ p: 1, mb: 4 }}>
                <OrderDetailsBox show={show} setShow={setShow} handleCloseDetails={handleCloseDetails} />
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
