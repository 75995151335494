import * as Yup from 'yup';
import React, { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import axios from 'axios';
// material
import { Stack, TextField, Button, Typography, IconButton, InputAdornment } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
//
import * as AuthActions from '../../../store/actions/user';
import AuthCalls from '../../../api/auth';

export default function EmailForm() {
  const [errorMsg, setErrorMsg] = useState('');
  const [next, setNext] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleResetPassword = async ({ email }) => {
    try {
      await AuthCalls.resetPassword(email);
      Swal.fire({
        icon: 'success',
        title: 'Password Reset',
        text: 'Successfully sent password reset token to email address',
      });
      navigate('/verify-token', { replace: true });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Password Reset Error',
        text: 'Unable to send password reset token to email address',
      });
    }
  };

  React.useEffect(() => {
    dispatch(AuthActions.resetLogin());
  }, []);

  const EmailSchema = Yup.object().shape({
    email: Yup.string().required('email address is required').email(),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: EmailSchema,
    onSubmit: (values) => {
      handleResetPassword(values);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Enter email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <Button fullWidth size="large" type="submit" variant="contained">
            Reset Password
          </Button>
          {errorMsg && <Typography sx={{ color: 'text.danger' }}>{errorMsg}</Typography>}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
