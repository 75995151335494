import * as Yup from 'yup';
import React, { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import axios from 'axios';
// material
import { Stack, TextField, Button, Typography, IconButton, InputAdornment } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
//
import * as AuthActions from '../../../store/actions/user';

export default function TokenForm() {
  const [errorMsg, setErrorMsg] = useState('');
  const [next, setNext] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const token = async (values) => {
    const { ...rest } = values;
    const data = {
      ...rest,
    };
    try {
      const { data: result, status } = await axios.post('https://api.coast.ng/api/v1/auth/verifyAdminLogin', {
        token: data.token,
      });
      if (status !== 200) {
        throw new Error();
      }
      dispatch({
        type: AuthActions.actionTypes.SET_USER,
        payload: result.data.user,
      });
      dispatch({
        type: AuthActions.actionTypes.SET_AUTH_TOKEN,
        payload: result.data.token,
      });
      navigate('/dashboard/app', { replace: true });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Invalid Token',
        text: 'Invalid token or token has expired',
      });
    }
  };

  React.useEffect(() => {
    dispatch(AuthActions.resetLogin());
  }, []);

  const TokenSchema = Yup.object().shape({
    token: Yup.string().required('Token is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: TokenSchema,
    onSubmit: (values) => {
      token(values);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="token"
            label="Enter token"
            {...getFieldProps('token')}
            error={Boolean(touched.token && errors.token)}
            helperText={touched.token && errors.token}
          />

          <Button fullWidth size="large" type="submit" variant="contained">
            Submit
          </Button>
          {errorMsg && <Typography sx={{ color: 'text.danger' }}>{errorMsg}</Typography>}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
