/* eslint-disable react/jsx-no-bind */
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

// material
import { Menu, MenuItem, IconButton, ListItemText } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import * as UserActions from '../../../store/actions/user';

// ----------------------------------------------------------------------

export default function UserMoreMenu() {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const { selectedUser } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  function alert(title, message) {
    Swal.fire({
      icon: 'success',
      title,
      text: message,
      didClose: () => dispatch(UserActions.setUsers()),
    });
  }

  function handleDemote() {
    dispatch(UserActions.demoteUser(selectedUser.user_id));
    alert(
      'User Demoted',
      `${selectedUser.first_name} ${selectedUser.last_name} has been demoted from the agent role successfully`
    );
  }

  function handleEnable() {
    dispatch(UserActions.enableUser(selectedUser.user_id));
    alert('User Enabled', `${selectedUser.first_name} ${selectedUser.last_name} has been enabled successfully`);
  }

  function handleDisable() {
    dispatch(UserActions.disableUser(selectedUser.user_id));
    alert('User Disabled', `${selectedUser.first_name} ${selectedUser.last_name} has been disabled successfully`);
  }

  function handleDelete() {
    dispatch(UserActions.deleteUser(selectedUser.user_id));
    alert('User Deleted', `${selectedUser.first_name} ${selectedUser.last_name} deleted successfully`);
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem componentnuItem={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={handleDemote}>
          <ListItemText primary="Demote" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem componentnuItem={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={handleEnable}>
          <ListItemText primary="Enable" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem componentnuItem={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={handleDisable}>
          <ListItemText primary="Disable" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDelete}>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
