/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable import/named */
import React, { useState } from 'react';
import {
  Box,
  Card,
  Table,
  Stack,
  Avatar,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Scrollbar from '../../../components/Scrollbar';
import AgentMoreMenu from './AgentMoreMenu';
import * as UserActions from '../../../store/actions/user';

const Agents = ({ columns, rows, userlist }) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();

  function handleUserSelect(userId) {
    dispatch(UserActions.setSelectedUser(userId));
    navigate(`/dashboard/users/${userId}`);
  }

  function handleMenuClicked(userId) {
    dispatch(UserActions.setSelectedUser(userId));
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Card>
        <Typography sx={{ fontSize: 18, p: 2 }} color="text.secondary" gutterBottom>
          Agents
        </Typography>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow hover tabIndex={-1} key={row.name}>
                    <TableCell component="th" scope="row" sx={{ px: 2 }}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar
                          sx={{ cursor: 'pointer' }}
                          key={row.userId}
                          alt={row.name}
                          src={row.avatar ?? 'https://www.meme-arsenal.com/memes/dc69087c94cb6ec44f899407d77a2839.jpg'}
                          onClick={handleUserSelect.bind(null, row.userId)}
                        />
                        <Typography variant="subtitle1" noWrap>
                          {row.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="subtitle1" noWrap>
                        {row.tag}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="subtitle1" noWrap>
                        {row.email}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        sx={{
                          color: !row.disabled ? 'text.success' : 'text.danger',
                          fontStyle: 'italic',
                        }}
                        variant="subtitle1"
                      >
                        {row.disabled ? 'Disabled' : 'Active'}
                      </Typography>
                    </TableCell>

                    <TableCell align="right" onClick={handleMenuClicked.bind(null, row.userId)}>
                      <AgentMoreMenu />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={userlist.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};
export default Agents;
