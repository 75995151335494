import React from 'react';
import { Box } from '@mui/material';
import GiftCardsLists from './GiftCardLists';

// const recentGifts = ['Steam'];
// const recentImg = [`/static/mock-images/covers/cover_30.png`];

function NewAsset({ gifts }) {
  return (
    <>
      {/* gifts={recentGifts} giftImg={recentImg} */}
      <GiftCardsLists gifts={gifts} />
    </>
  );
}

export default NewAsset;
