/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Grid, Card, Stack, Typography, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Profile from '../../../components/Profile';

export default function AdminProfile() {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);

  return (
    <Profile>
      <Card sx={{ p: 5 }}>
        <Grid
          container
          rowSpacing={1}
          justifyContent={'center'}
          alignItems={'flex-start'}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mt: 5 }}
        >
          <Grid item xs={4} justifyContent={'center'} alignItems={'center'}>
            <Stack justifyContent={'start'} alignItems={'start'} sx={{ mt: 0 }}>
              <Box
                component="img"
                alt="profile"
                src={user.avatar ?? 'https://www.meme-arsenal.com/memes/dc69087c94cb6ec44f899407d77a2839.jpg'}
                sx={{ borderRadius: '5%' }}
              />
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>Personal Information</Typography>
            <Box sx={{ mb: 3 }} />
            <Stack
              flexDirection={'row'}
              columnGap={12}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              sx={{ mb: '8px' }}
            >
              <Box>
                <Typography>Name</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>Email Address</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>Role</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>Username</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>User Tag</Typography>
                <Box sx={{ mb: 3 }} />
              </Box>
              <Box>
                <Typography>{`${user.first_name} ${user.last_name}`}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{user.email}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{`${user.role || 'Superuser'}`}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{user.username}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{user.user_tag}</Typography>
                <Box sx={{ mb: 3 }} />
              </Box>
            </Stack>
            <Divider />
            <Box sx={{ mb: 3 }} />
            <Typography sx={{ fontSize: '30px', fontWeight: 'bold' }}>Profile</Typography>
            <Box sx={{ mb: 4 }} />
            <Box />
            <Stack
              flexDirection={'row'}
              columnGap={12}
              justifyContent={'start'}
              alignItems={'center'}
              sx={{ mb: '8px' }}
            >
              <Box>
                <Typography sx={{ color: 'text.secondary' }}>Date Registered</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>Phone Number</Typography>
                <Box sx={{ mb: 3 }} />
              </Box>
              <Box>
                <Typography>{new Date(user.date_registered).toLocaleString()}</Typography>
                <Box sx={{ mb: 3 }} />
                <Typography>{user.phone_number}</Typography>
                <Box sx={{ mb: 3 }} />
              </Box>
            </Stack>

            <LoadingButton size="large" variant="contained" onClick={() => navigate('/dashboard/settings')}>
              Update Profile
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
    </Profile>
  );
}
