import React, { useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import PieChart from '../../../components/chart/PieChart';

export default function OrderBreak() {
  const pieOptions = {
    plugins: {
      datalabels: {
        render: 'label',
      },
    },
  };

  const [pieuserData, setPieUserData] = useState({
    labels: ['Fulfilled', 'Pending', 'Rejected'],
    datasets: [
      {
        data: [63, 25, 12],
        backgroundColor: ['#007CF6', '#6AD2FF', '#D6E3F5'],
      },
    ],
  });

  return (
    <Box>
      <Card
        sx={{
          pb: 10,
        }}
      >
        <Typography sx={{ fontSize: 18, p: 2 }} color="text.secondary" gutterBottom>
          Order Break Down
        </Typography>

        <PieChart chartData={pieuserData} options={pieOptions} />
      </Card>
    </Box>
  );
}
