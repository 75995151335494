/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
// material
import { Stack, Button, Container, MenuItem, FormControl, Select, Box } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

import { Agents, Users, Subagents } from '../sections/@dashboard/user';
import * as UserActions from '../store/actions/user';

const columns = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'userId', label: 'User Tag', alignRight: false },
  { id: 'email', label: 'Email address', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

class UserToRender {
  constructor(user) {
    this.userId = user.user_id;
    this.name = `${user.first_name} ${user.last_name}`;
    this.tag = user.user_tag;
    this.email = user.email;
    this.disabled = user.disabled;
    this.avatar = user.avatar;
  }
}

export default function User() {
  const useStyles = makeStyles({
    btn: {
      backgroundColor: '#007CF6',
      border: 'none',
      fontSize: '12px',
      color: '#fff',

      '&:hover': {
        backgroundColor: '#007CF6',
      },
    },
    selectForm: {
      width: '100px',
    },
    selectInput: {
      borderColor: 'gray',
      fontSize: '11px',
    },
    faintButton: {
      backgroundColor: 'transparent',
      fontStyle: 'italic',
    },
  });

  const classes = useStyles();

  const [status, setStatus] = useState('');

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  let { users, agents, subagents } = useSelector((state) => state.user);

  users = users.map((user) => new UserToRender(user));
  agents = agents.map((agent) => new UserToRender(agent));
  subagents = subagents !== undefined ? subagents.map((agent) => new UserToRender(agent)) : [];

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(UserActions.setUsers(status));
  }, [status]);

  return (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          bgcolor={'background.paper'}
          sx={{ borderRadius: 1, px: 4, py: 1 }}
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Stack direction="row" alignItems="center" spacing={1} width="300px" justifyContent="space-around">
            <FormControl sx={{ mt: 1, minWidth: 100 }} fullWidth>
              <Select
                value={status}
                onChange={handleChange}
                displayEmpty
                inputProps={{
                  'aria-label': 'Without label',
                }}
              >
                <MenuItem value="">
                  <em>Status</em>
                </MenuItem>
                <MenuItem value={'active'}>Active</MenuItem>
                <MenuItem value={'disabled'}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Button className={classes.btn} endIcon={<Iconify icon="ic:round-filter-list" />}>
            Filter
          </Button>
        </Stack>

        <Agents columns={columns} rows={agents} userlist={agents} />
        <Subagents columns={columns} rows={subagents} userlist={subagents} />
        <Users columns={columns} rows={users} userlist={users} />
      </Container>
    </Page>
  );
}
