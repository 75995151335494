import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Stack, Container, Typography, Grid, Card } from '@mui/material';
import Page from '../components/Page';
import TradeHistory from '../sections/@dashboard/statistics/TradeHistory';
import OrderBreak from '../sections/@dashboard/statistics/OrderBreak';
import PlatformType from '../sections/@dashboard/statistics/PlatformType';
import Agents from '../sections/@dashboard/app/Agents';

export default function Statistics() {
  const {
    totalRevenue: revenue,
    totalOrders: orders,
    totalFulfilledOrders: fulfilledOrders,
    totalUsers: users,
  } = useSelector((state) => state.statistics);
  const { topUsers } = useSelector((state) => state.user);

  return (
    <Page title="Statistics">
      <Container>
        <Stack
          direction="row"
          bgcolor={'background.paper'}
          sx={{ borderRadius: 1, px: 4, py: 1 }}
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography as="h3" sx={{ fontWeight: '700', fontSize: '18px' }}>
              {revenue}
            </Typography>
            <Typography sx={{ fontSize: '12px', color: 'text.secondary' }}>Total revenue</Typography>
          </Box>

          <Box sx={{ textAlign: 'center' }}>
            <Typography as="h3" sx={{ fontWeight: '700', fontSize: '18px' }}>
              {orders}
            </Typography>
            <Typography sx={{ fontSize: '12px', color: 'text.secondary' }}>Total Orders</Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography as="h3" sx={{ fontWeight: '700', fontSize: '18px' }}>
              {fulfilledOrders}
            </Typography>
            <Typography sx={{ fontSize: '12px', color: 'text.secondary' }}>Fulfilled Orders</Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography as="h3" sx={{ fontWeight: '700', fontSize: '18px' }}>
              {users}
            </Typography>
            <Typography sx={{ fontSize: '12px', color: 'text.secondary' }}>Number of Users</Typography>
          </Box>
        </Stack>

        <TradeHistory />

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 5 }}>
          <Grid item xs={4}>
            <OrderBreak />
          </Grid>
          <Grid item xs={8}>
            <PlatformType />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 5 }}>
          <Grid item xs={4}>
            <Card sx={{ p: 1, mb: 4 }}>
              <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                Top users by most fulfilled orders
              </Typography>
              {topUsers.map((user, index) => (
                <Agents
                  key={`${user.owner_tag}` - `${index}`}
                  name={`${user.owner_tag}`}
                  amount={user.successfulorders}
                  image={'https://www.w3schools.com/w3images/avatar2.png'}
                  userId={user.owner}
                />
              ))}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
