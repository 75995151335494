import GiftcardCalls from '../../api/giftcard';

export const actionTypes = {
  SET_GIFTCARDS: 'SET_GIFTCARDS',
  SET_SELECTED_GIFTCARD: 'SET_SELECTED_GIFTCARD',
  SET_GIFTCARD_TYPES: 'SET_GIFTCARD_TYPES',
  SET_GIFTCARD_RANGES: 'SET_GIFTCARD_RANGES',
  SET_GIFTCARD_COUNTRIES: 'SET_GIFTCARD_COUNTRIES',
  SET_GIFTCARD_ERROR: 'SET_GIFTCARD_ERROR',
  DELETE_GIFTCARD: 'DELETE_GIFTCARD',
  SET_RECENT_GIFTCARDS: 'SET_RECENT_GIFTCARDS',
};

export function getGiftcards() {
  return async function (dispatch, getState) {
    try {
      const { giftcards } = await GiftcardCalls.getGiftcards();
      const currentGiftcard = giftcards.length && giftcards[0];
      const recentGiftcards = giftcards.filter((giftcard) => new Date(giftcard?.created_on) === new Date(Date.now()));

      dispatch({
        type: actionTypes.SET_GIFTCARDS,
        payload: giftcards,
      });
      dispatch({
        type: actionTypes.SET_SELECTED_GIFTCARD,
        payload: currentGiftcard,
      });
      dispatch({
        type: actionTypes.SET_RECENT_GIFTCARDS,
        payload: recentGiftcards,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.SET_GIFTCARD_ERROR,
        payload: 'unable to fetch giftcards',
      });
    }
  };
}

export function getGiftcardTypes(giftcardId) {
  return async function (dispatch, getState) {
    try {
      const giftcardTypes = await GiftcardCalls.getGiftcardTypes(giftcardId);
      dispatch({
        type: actionTypes.SET_GIFTCARD_TYPES,
        payload: giftcardTypes,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.SET_GIFTCARD_ERROR,
        payload: 'unable to fetch giftcard types',
      });
    }
  };
}

export function getGiftcardRanges() {
  return async function (dispatch, getState) {
    try {
      const ranges = await GiftcardCalls.getGiftcardRanges();
      dispatch({
        type: actionTypes.SET_GIFTCARD_RANGES,
        payload: ranges,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.SET_GIFTCARD_ERROR,
        payload: 'unable to fetch giftcard ranges',
      });
    }
  };
}

export function getGiftcardCountries() {
  return async function (dispatch, getState) {
    try {
      const countries = await GiftcardCalls.getGiftcardCountries();
      dispatch({
        type: actionTypes.SET_GIFTCARD_COUNTRIES,
        payload: countries,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.SET_GIFTCARD_ERROR,
        payload: 'unable to fetch giftcard countries',
      });
    }
  };
}

export function setSelectedGiftcard(giftcardId) {
  return async function (dispatch, getState) {
    const giftcards = getState().giftcard.giftcards;
    const giftcard = giftcards.find((giftcard) => giftcard.id === giftcardId);
    dispatch({
      type: actionTypes.SET_SELECTED_GIFTCARD,
      payload: giftcard,
    });
  };
}

export function deleteGiftcard(giftId) {
  return async function (dispatch, getState) {
    await GiftcardCalls.deleteGiftcard(giftId);
    dispatch({
      type: actionTypes.DELETE_GIFTCARD,
      payload: giftId,
    });
  };
}

export function createGiftcard() {}
