/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Zoom } from 'react-slideshow-image';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'react-slideshow-image/dist/styles.css';

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Stack, Typography, Tab, Divider, useMediaQuery, Dialog, Button, DialogContent } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import PendingOrderDialog from './PendingOrderDialog';
import FulfilledOrderDialog from './FulfilledOrderDialog';
import RejectedOrderDialog from './RejectedOrderDialog';
import { fCurrency } from '../../../utils/formatNumber';
import { fDateTime } from '../../../utils/formatTime';
import * as StatisticsActions from '../../../store/actions/statistics';
import * as TradeActions from '../../../store/actions/trade';
import * as UserActions from '../../../store/actions/user';
import ConfirmationDialog from './ConfirmationDialog';

class GiftcardOrderDetails {
  constructor(order) {
    this.name = `${order.name} Giftcard`;
    this.country = order.country;
    this.amount = `${fCurrency(order.amount_in_naira)}`;
    this.type = order.type;
    this.value = `$${order.value}`;
    this.orderId = order.order_id;
    this.userId = order.user_id;
    this.userTag = order.user_tag;
    this.orderDate = new Date(order.traded_on).toISOString();
    this.rate = `$${order.rate}`;
    this.receipt = order.receipt_needed ? 'Yes' : 'None';
    this.giftcardReceipts = order.giftcardReceipts;
    this.giftcardImages = order.giftcardImages;
  }
}

export default function OrderDetails() {
  const useStyles = makeStyles({
    faintButton: {
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: '10px',
      fontWeight: '600',
    },
  });

  const classes = useStyles();

  const [value, setValue] = useState('1');
  const [openFulfill, setOpenFulfill] = useState(false);
  const [openPending, setOpenPending] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [openImageSlider, setOpenImageSlider] = useState(false);
  const [imageType, setImageType] = useState('giftcard');

  const { currentOrder } = useSelector((state) => state.trade);

  const order = new GiftcardOrderDetails(currentOrder);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function refreshDashboard() {
    dispatch(StatisticsActions.getTotalRevenue());
    dispatch(StatisticsActions.getTotalOrders());
    dispatch(StatisticsActions.getTotalOrders('fulfilled'));
    dispatch(StatisticsActions.getTotalUsers());
    dispatch(TradeActions.getGiftcardOrders());
  }

  const handleFullfillOpen = (e) => {
    e.stopPropagation();
    setOpenFulfill(true);
    refreshDashboard();
  };
  const handleFullfillClose = () => {
    setOpenFulfill(false);
  };

  const handlePendingOpen = (e) => {
    e.stopPropagation();
    setOpenPending(true);
    refreshDashboard();
  };
  const handlePendingClose = () => {
    setOpenPending(false);
  };

  const handleRejectOpen = (e) => {
    e.stopPropagation();
    setOpenReject(true);
    refreshDashboard();
  };
  const handleRejectClose = () => {
    setOpenReject(false);
  };

  function handleOpenImageSlider(imageType) {
    setImageType(imageType);
    setOpenImageSlider(true);
  }

  function handleImagesDownload(event) {
    event.stopPropagation();
    order.giftcardImages.forEach(({ image }, index) => saveAs(image, `giftcard_image_${index + 1}`));
    Swal.fire({
      icon: 'success',
      title: 'Giftcard Images Downloaded',
      text: 'The giftcard images has been successfully downloaded!',
      buttonsStyling: true,
    });
  }

  function handleReceiptsDownload(event) {
    event.stopPropagation();
    order.giftcardReceipts.forEach(({ image }, index) => saveAs(image, `giftcard_receipt_${index + 1}`));
    Swal.fire({
      icon: 'success',
      title: 'Giftcard Receipts Downloaded',
      text: 'The giftcard receipts has been successfully downloaded!',
      buttonsStyling: true,
    });
  }

  function handleUserSelect(userId) {
    dispatch(UserActions.setSelectedUser(userId));
    navigate(`/dashboard/users/${userId}`);
  }

  return (
    <Box>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="tabs"
            centered
            sx={{
              px: 2,
            }}
          >
            <Tab label="Giftcard" value="1" />
            <Tab label="Receipt" value="2" />
          </TabList>
        </Box>
        <TabPanel
          value="1"
          sx={{
            px: 0,
          }}
        >
          <Box onClick={handleOpenImageSlider.bind(null, 'giftcard')}>
            <Button sx={{ width: '50%', margin: '0 auto' }} variant="outlined" onClick={handleImagesDownload}>
              Download Images
            </Button>
            <Box
              sx={{
                p: 2,
              }}
            >
              <img src={`${order.giftcardImages[0].image}`} alt="gift" />
            </Box>

            <Stack direction="row" spacing={4} sx={{ mb: 1, px: 2 }}>
              <Typography fontSize={'14px'} color="text.secondary">
                Amount to be Paid(#)
              </Typography>
              <Typography fontWeight={'600'}>{`${order.amount} Naira`}</Typography>
            </Stack>
            <Stack direction="row" spacing={6} sx={{ mb: 1, px: 2 }}>
              <Typography
                as="button"
                className={classes.faintButton}
                onClick={(e) => handleFullfillOpen(e)}
                color="text.success"
              >
                Fulfill Order
              </Typography>
              <Typography
                as="button"
                className={classes.faintButton}
                color="text.warning"
                onClick={(e) => handlePendingOpen(e)}
              >
                Hold Order
              </Typography>
              <Typography
                as="button"
                className={classes.faintButton}
                onClick={(e) => handleRejectOpen(e)}
                color="text.danger"
              >
                Reject Order
              </Typography>
            </Stack>
            <Divider />

            <Stack direction="row" spacing={2} sx={{ px: 2, py: 2, mb: 1 }} onClick={(e) => e.stopPropagation()}>
              <Stack spacing={2} color="text.secondary">
                <Typography>Name</Typography>
                <Typography>Type</Typography>
                <Typography>Value</Typography>
                <Typography>Country</Typography>
                <Typography>Rate</Typography>
                <Typography>Receipt</Typography>
                <Typography>User</Typography>
                <Typography>Order Date</Typography>
              </Stack>
              <Stack spacing={2}>
                <Typography>{order.name}</Typography>
                <Typography>{order.type}</Typography>
                <Typography>{order.value}</Typography>
                <Typography>{order.country}</Typography>
                <Typography>{order.rate}</Typography>
                <Typography>{order.receipt}</Typography>
                <Typography onClick={handleUserSelect.bind(null, order.userId)} sx={{ cursor: 'pointer' }}>
                  {order.userTag}
                </Typography>
                <Typography>{fDateTime(order.orderDate)}</Typography>
              </Stack>
            </Stack>
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box onClick={handleOpenImageSlider.bind(null, 'receipt')}>
            {order?.giftcardReceipts?.length && (
              <Button sx={{ width: '50%', margin: '0 auto' }} variant="outlined" onClick={handleReceiptsDownload}>
                Download Receipts
              </Button>
            )}
            <Box
              sx={{
                p: 2,
              }}
            >
              <img
                src={`${
                  order.giftcardReceipts
                    ? order.giftcardReceipts[0].image
                    : 'https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg'
                }`}
                alt="gift"
              />
            </Box>
          </Box>
        </TabPanel>
      </TabContext>

      <ImageSlider
        open={openImageSlider}
        onClose={() => setOpenImageSlider(false)}
        images={imageType === 'giftcard' ? order.giftcardImages : order.giftcardReceipts}
      />
      <FulfilledOrderDialog
        order={order}
        open={openFulfill}
        handleFullfillClose={handleFullfillClose}
        fullScreen={fullScreen}
        action={refreshDashboard}
      />
      <PendingOrderDialog
        order={order}
        open={openPending}
        handlePendingClose={handlePendingClose}
        fullScreen={fullScreen}
      />
      <RejectedOrderDialog
        order={order}
        open={openReject}
        handleRejectClose={handleRejectClose}
        fullScreen={fullScreen}
      />
    </Box>
  );
}

function ImageSlider({ open, onClose, images }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return images ? (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          display: 'block',
          margin: '0 auto',
        }}
      >
        <DialogContent width={'100%'} height={'100%'}>
          <Box className="slide-container">
            <Zoom scale={0.4}>
              {images &&
                images.map((image) => (
                  <img style={{ width: '100%' }} key={image.id} src={image.image} alt={`${image.id}`} />
                ))}
            </Zoom>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  ) : null;
}
