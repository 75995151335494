/* eslint-disable prefer-const */
import AuthCalls from '../../api/auth';
import UserCalls from '../../api/user';

export const actionTypes = {
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  SET_USER: 'SET_USER',
  SET_USER_ERROR: 'SET_USER_ERROR',
  SET_LOGIN_SUCCESS: 'SET_LOGIN_SUCCESS',
  LOG_OUT: 'LOG_OUT',
  SET_USERS: 'SET_USERS',
  SET_AGENTS: 'SET_AGENTS',
  SET_SUBAGENTS: 'SET_SUBAGENTS',
  SET_SELECTED_USER: 'SET_SELECTED_USER',
  SET_TRIED_LOGIN: 'SET_TRIED_LOGIN',
  SET_TOP_USERS: 'SET_TOP_USERS',
};

export function login(username, password) {
  return async function (dispatch, getState) {
    try {
      await AuthCalls.login(username, password);
      dispatch({
        type: actionTypes.SET_USER_ERROR,
        payload: '',
      });
      dispatch({
        type: actionTypes.SET_LOGIN_SUCCESS,
        payload: true,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.SET_USER_ERROR,
        payload: 'unable to login, please check credentials',
      });
      dispatch({
        type: actionTypes.SET_LOGIN_SUCCESS,
        payload: false,
      });
    }
  };
}

export function verifyToken(code) {
  return async function (dispatch, getState) {
    try {
      const { token, user } = await AuthCalls.verifyToken(code);
      dispatch({
        type: actionTypes.SET_AUTH_TOKEN,
        payload: token,
      });
      dispatch({
        type: actionTypes.SET_USER,
        payload: user,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.SET_USER_ERROR,
        payload: 'unable to verify token',
      });
    }
  };
}

export function setUser(user) {
  return async function (dispatch, getState) {
    dispatch({
      type: actionTypes.SET_USER,
      payload: user,
    });
  };
}

export function setAuthToken(token) {
  return async function (dispatch, getState) {
    dispatch({
      type: actionTypes.SET_AUTH_TOKEN,
      payload: token,
    });
  };
}

export function resetLogin() {
  return async function (dispatch, getState) {
    dispatch({
      type: actionTypes.SET_USER_ERROR,
      payload: '',
    });
    dispatch({
      type: actionTypes.SET_TRIED_LOGIN,
      payload: true,
    });
  };
}

export function logout() {
  return async function (dispatch, getState) {
    dispatch({
      type: actionTypes.LOG_OUT,
    });
  };
}

export function setLoginSuccess(value) {
  return async function (dispatch, getState) {
    dispatch({
      type: actionTypes.SET_LOGIN_SUCCESS,
      payload: value,
    });
  };
}

export function setClientToken(clientToken) {
  return async function (dispatch, getState) {
    UserCalls.setClientToken(clientToken);
  };
}

export function setUsers(status) {
  return async function (dispatch, getState) {
    let data;
    if (!status) {
      data = await UserCalls.getUsers();
    } else {
      data = getState().user.users;
    }
    let users = data.filter((user) => user.role === 'user');
    let agents = data.filter((user) => user.role === 'agent');
    let subagents = data.filter((user) => user.role === 'sub-agent');
    if (status === 'active') {
      users = data.filter((user) => user.disabled === false && user.role === 'user');
      agents = data.filter((user) => user.disabled === false && user.role === 'agent');
      subagents = data.filter((user) => user.disabled === false && user.role === 'sub-agent');
    } else if (status === 'disabled') {
      users = data.filter((user) => user.disabled === true && user.role === 'user');
      agents = data.filter((user) => user.disabled === true && user.role === 'agent');
      subagents = data.filter((user) => user.disabled === true && user.role === 'sub-agent');
    }
    dispatch({
      type: actionTypes.SET_USERS,
      payload: users,
    });
    dispatch({
      type: actionTypes.SET_AGENTS,
      payload: agents,
    });
    dispatch({
      type: actionTypes.SET_SUBAGENTS,
      payload: subagents,
    });
  };
}

export function setSelectedUser(userId) {
  return async function (dispatch, getState) {
    const { users, agents, subagents } = getState().user;
    const data = users.concat(agents, subagents);
    const selectedUser = data.find((user) => user.user_id === userId);
    dispatch({
      type: actionTypes.SET_SELECTED_USER,
      payload: selectedUser,
    });
  };
}

export function setTopUsers(userId) {
  return async function (dispatch, getState) {
    const topUsers = await UserCalls.getTopUsers();
    dispatch({
      type: actionTypes.SET_TOP_USERS,
      payload: topUsers,
    });
  };
}

export function enableUser(userId) {
  return async function (dispatch, getState) {
    await UserCalls.enableUser(userId);
  };
}

export function disableUser(userId) {
  return async function (dispatch, getState) {
    await UserCalls.disableUser(userId);
  };
}

export function deleteUser(userId) {
  return async function (dispatch, getState) {
    await UserCalls.deleteUser(userId);
  };
}

export function elevateUser(userId) {
  return async function (dispatch, getState) {
    await UserCalls.elevateUser(userId);
  };
}

export function elevateUserToSubagent(userId) {
  return async function (dispatch, getState) {
    await UserCalls.elevateToSubAgent(userId);
  };
}

export function demoteUser(userId) {
  return async function (dispatch, getState) {
    await UserCalls.demoteUser(userId);
  };
}

export function forgetPassword(email) {
  return async function (dispatch, getState) {
    await AuthCalls.resetPassword(email);
  };
}
