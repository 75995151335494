/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import { useNavigate } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Stack, Typography, Tab, Divider, useMediaQuery, Dialog, Button, DialogContent } from '@mui/material';
import { fDateTime } from '../utils/formatTime';
import { fCurrency } from '../utils/formatNumber';
import * as UserActions from '../store/actions/user';

export default function OrderInformation({ selectedOrder }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleUserSelect(userId) {
    dispatch(UserActions.setSelectedUser(userId));
    navigate(`/dashboard/users/${userId}`);
  }

  React.useEffect(() => {}, [JSON.stringify(selectedOrder)]);

  return (
    <Stack direction="row" spacing={2} sx={{ px: 2, py: 2, mb: 1 }} onClick={(e) => e.stopPropagation()}>
      <Stack spacing={2} color="text.secondary">
        <Typography noWrap align="left">
          Order Ref
        </Typography>
        <Typography noWrap align="left">
          Name
        </Typography>
        <Typography noWrap align="left">
          Type
        </Typography>
        <Typography noWrap align="left">
          Value($)
        </Typography>
        <Typography noWrap align="left">
          Country
        </Typography>
        <Typography noWrap align="left">
          Rate($)
        </Typography>
        <Typography noWrap align="left">
          Range
        </Typography>
        <Typography noWrap align="left">
          Provided receipt?
        </Typography>
        <Typography noWrap align="left">
          Provided images?
        </Typography>
        <Typography noWrap align="left">
          User Tag
        </Typography>
        <Typography noWrap align="left">
          Status
        </Typography>
        {selectedOrder?.status === 'rejected' && selectedOrder?.rejected_reason && (
          <Typography noWrap>Rejected Reason</Typography>
        )}
        {selectedOrder?.status === 'held' && selectedOrder?.held_reason && <Typography noWrap>Held Reason</Typography>}
        {selectedOrder?.status === 'held' && selectedOrder?.held_reason && (
          <Typography noWrap> Provided Solution</Typography>
        )}
        <Typography noWrap align="left">
          Order Date
        </Typography>
        <Typography noWrap align="left">
          Amount
        </Typography>
        <Typography noWrap align="left">
          Attended By
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography noWrap>{selectedOrder?.order_ref}</Typography>
        <Typography noWrap>{selectedOrder?.name}</Typography>
        <Typography noWrap>{selectedOrder?.type}</Typography>
        <Typography noWrap>{`$${selectedOrder?.value}`}</Typography>
        <Typography noWrap>{selectedOrder?.country}</Typography>
        <Typography noWrap>{`$${selectedOrder?.rate}`}</Typography>
        <Typography noWrap>{selectedOrder?.range}</Typography>
        <Typography noWrap>{selectedOrder?.receipt_needed ? 'Yeah' : 'Nope'}</Typography>
        <Typography noWrap>{selectedOrder?.giftcardImages.length > 0 ? 'Yes' : 'No'}</Typography>
        <Typography
          noWrap
          onClick={handleUserSelect.bind(null, selectedOrder?.user_id)}
          sx={{ cursor: 'pointer' }}
          fontWeight={'600'}
        >
          {selectedOrder?.user_tag}
        </Typography>
        <Typography
          noWrap
          sx={{
            color:
              selectedOrder?.status === 'fulfilled'
                ? 'text.success'
                : selectedOrder?.status === 'rejected'
                ? 'text.danger'
                : 'text.warning',
          }}
        >
          {selectedOrder?.status}
        </Typography>
        {selectedOrder?.status === 'rejected' && selectedOrder?.rejected_reason && (
          <Typography noWrap fontWeight={'600'}>
            {selectedOrder?.rejected_reason.reason}
          </Typography>
        )}
        {selectedOrder?.status === 'held' && selectedOrder?.held_reason && (
          <Typography noWrap fontWeight={'600'}>
            {selectedOrder?.held_reason.reason}
          </Typography>
        )}
        {selectedOrder?.status === 'held' && selectedOrder?.held_reason && (
          <Typography noWrap fontWeight={'600'}>
            {selectedOrder?.held_reason.solution}
          </Typography>
        )}
        <Typography noWrap>
          {selectedOrder?.traded_on && new Date(selectedOrder?.traded_on).toLocaleString()}
        </Typography>
        <Typography noWrap fontWeight={'600'}>{`${fCurrency(selectedOrder?.amount_in_naira)} Naira`}</Typography>
        <Typography noWrap fontWeight={'600'}>
          {selectedOrder?.attended_by ? selectedOrder?.attended_by : 'No one yet'}
        </Typography>
      </Stack>
    </Stack>
  );
}
