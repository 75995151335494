/* eslint-disable default-param-last */
import { actionTypes } from '../actions/statistics';

const initialState = {
  totalRevenue: 0,
  totalOrders: 0,
  totalFulfilledOrders: 0,
  totalUsers: 0,
  topAgents: [],
};

export default function statisticsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_TOTAL_REVENUE:
      return {
        ...state,
        totalRevenue: action.payload,
      };

    case actionTypes.SET_TOTAL_ORDERS:
      return {
        ...state,
        totalOrders: action.payload,
      };

    case actionTypes.SET_FULFILLED_ORDERS:
      return {
        ...state,
        totalFulfilledOrders: action.payload,
      };

    case actionTypes.SET_TOTAL_USERS:
      return {
        ...state,
        totalUsers: action.payload,
      };

    case actionTypes.SET_TOP_AGENTS:
      return {
        ...state,
        topAgents: action.payload,
      };

    default:
      return state;
  }
}
