/* eslint-disable react/prop-types */
import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@material-ui/core';
import OrderDetails from './OrderDetails';
import OrderInformation from '../../../pages/OrderInformation';

const BootstrapBox = styled(Box)(({ theme }) => ({
  '& .MuiDialog-container': {
    justifyContent: 'end',
    position: 'relative',
    top: '30%',
  },

  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function OrderDetailsCard({ handleCloseDetails, selectedOrder }) {
  return (
    <Box>
      <BootstrapBox>
        <Box sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseDetails}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          {selectedOrder ? 'More Information' : 'Order Details'}
        </Box>
        <Box>{!selectedOrder ? <OrderDetails /> : <OrderInformation selectedOrder={selectedOrder} />}</Box>
      </BootstrapBox>
    </Box>
  );
}
