import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box } from '@mui/material';
import Grid from '@material-ui/core/Grid';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../images/coast.png';
// import { logo } from '../images/logo.png';
// sections
import { LoginForm } from '../sections/auth/login';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  backgroundColor: '#FFFFFF',
  height: '100vh',
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const BoxStyle = styled(Card)(({ theme }) => ({
  overflow: 'hidden',
  backgroundColor: '#0655FF',
  borderRadius: '0px',
  height: '100vh',
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 552,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '0px',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '90px 12px',
  backgroundColor: '#FFFFFF',
}));

export default function Login() {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        {
          <Grid container sx={{ zIndex: '1000' }} columnSpacing={{ xs: 1, sm: 2, md: 3 }} item sm={6} md={6} xs={12}>
            <Box
              component="img"
              src={Logo}
              alt={`logo`}
              sx={{
                minHeight: 'auto',
                objectFit: mdUp ? 'cover' : 'contain',
                maxWidth: '100%',
              }}
            />
            {''}
            {/* <ImageStyle /> */}
          </Grid>
        }

        <Grid container sx={{ zIndex: '500', minHeight: '50vh' }} item xs={12} sm={6} md={6}>
          <ContentStyle>
            <Box
              component="img"
              sx={{ margin: '0 auto', width: '50px' }}
              src="/static/logo/Subtract.png"
              alt={`logo`}
            />
            <Typography variant="h4" sx={{ padding: '.5rem 0', color: 'text.main' }} gutterBottom>
              Welcome Back!
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Please login to your account</Typography>

            <LoginForm />

            {/* {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register" style={{ cursor: 'pointer' }}>
                  Get started
                </Link>
              </Typography>
            )} */}
          </ContentStyle>
        </Grid>
      </RootStyle>
    </Page>
  );
}
