/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Dialog, Button, DialogContent, DialogContentText } from '@mui/material';
import { styled } from '@mui/material/styles';

import Swal from 'sweetalert2';

import Iconify from '../../../components/Iconify';
import * as TradeActions from '../../../store/actions/trade';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
}));

const FulfillOrder = ({ open, handleFullfillClose, fullScreen, order, action }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleSubmit() {
    dispatch(TradeActions.attendToGiftcardOrder('fulfill', order.orderId, undefined));
    Swal.fire({
      icon: 'success',
      title: 'Order Fulfilled!',
      text: 'Order has been fulfilled successfully',
      buttonsStyling: true,
      didClose: dispatch(TradeActions.getGiftcardOrders()),
    });
    dispatch(TradeActions.getGiftcardOrders());
    handleFullfillClose();
  }

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleFullfillClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          display: 'block',
          margin: '0 auto',
        }}
      >
        <DialogContent>
          <IconWrapperStyle>
            <Iconify icon="bi:patch-question" width={'50%'} height={'50%'} color={'text.info'} />
          </IconWrapperStyle>
          <DialogContentText mt={2}>Are you sure you want to fulfill this order?</DialogContentText>
        </DialogContent>
        <Box p={2}>
          <Button sx={{ width: '100%' }} variant="contained" onClick={handleSubmit}>
            Yes, I want to fulfill this order!
          </Button>
          <Box sx={{ mb: 2 }} />
          <Button sx={{ width: '100%' }} variant="outlined" onClick={handleFullfillClose}>
            No, I don't!
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default FulfillOrder;
